import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "./../../components/PageTitle";
import BodyClass from "./../../components/BodyClass";
import AutoplayVideo from "./../../components/AutoplayVideo";

import ImageFictionPoster from "./../../images/cover/paprika_fiction_header_photo_desktop_alt.jpg";
import ImageFictionPosterMobile from "./../../images/cover/paprika_fiction_header_photo.jpg";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const Fiction = () => {
	return (
		<>
			<PageTitle title="Fiction Highlights" />
			<BodyClass className={["page-latest-projects", "fiction"]} />

			<section id="hero" className="section projects-hero">
				<div className="projects-hero__background">
					<AutoplayVideo
						src="/videos/paprika_fiction_header_video.mp4"
						poster={ImageFictionPoster}
						posterMobile={ImageFictionPosterMobile}
						className="services-image"
					/>
				</div>

				<Container>
					<h1>
						<mark>Fiction</mark> Highlights
					</h1>
				</Container>
			</section>

			<section id="projects-about" className="section projects-about">
				<Container>
					<Row>
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">
								Paprika <br className="d-block d-lg-block" /> Fiction
							</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								We create and develop original scripts in-house, adapt
								international fiction hits for CEE markets and produce daily and
								weekly drama series with local casts in our territories. We are
								proud to create high quality fiction content in eight countries
								with our experienced crews.
							</p>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">We create</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								Our skilled, multinational writers’ team takes pride in creating
								original concepts for fiction in every genre, following the
								regional, and international trends, meeting the audiences
								constantly rising expectations, with engaging ways of
								storytelling. In order to widen the horizon, Paprika has also
								brought <Link to="/#hypewriter" className="fw-bold">Hypewriter</Link> to life, an
								international pitch competition for original ideas for TV
								series.
							</p>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">We develop</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								Based on the original ideas with the highest potential for
								success, our professionals in the central, and local writers’
								rooms are constantly working on scripts and other written
								materials to ensure that the newly developed concepts will meet
								the expectations of the clients and most importantly our
								audiences.
							</p>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">We adapt</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								Besides creating and developing original concepts we have gained
								vast experience in adapting international fiction hits in the
								local CEE countries. Our writers, and their experience ensures
								that every title will have the touch, flavor, and most
								importantly the relevance to the local markets. Our adaptations
								are more than just remakes and copies, they all have the desired
								tone, that is key to success, and would not work, without our
								writers’ full comprehension and feeling for their local viewers.
							</p>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">We produce</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								Our highly skilled local production teams ensure, that all the
								work that goes into creating, developing, adapting and writing
								will result in a high quality and cost effective production. On
								one hand we are distributing our cross border production
								expertise between our countries by involving international
								showrunners in the process, and on the other hand we are working
								with the best, and the most experienced local professionals. We
								take pride in not only meeting the various local production
								standards but also in exceeding them.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<RelatedProjectsFiction />

			<section id="projects-gallery" className="section projects-gallery">
				<Container className="py-5" style={{ backgroundColor: "#ddd" }} fluid>
					<p className="text-center mb-0 text-black">gallery here </p>
				</Container>
			</section>

			<section
				id="projects-about-secondary"
				className="section projects-about-secondary"
			>
				<Container>
					<Row>
						<Col lg={{ span: 1, order: 1 }} className="d-none d-lg-block"></Col>

						<Col md={{ span: 4, order: 4 }}>
							<h2 className="text-center text-md-start">
								More <br className="d-block d-lg-block" />
								about <br className="d-block d-lg-block" />
								this
							</h2>
						</Col>

						<Col md={{ span: 2, order: 3 }} className="d-none d-md-block"></Col>

						<Col md={{ span: 6, order: 2 }} lg={4}>
							<p>
								Proin felis arcu, finibus quis est quis, dapibus faucibus odio.
								Sed lectus metus, cursus ut ex quis, suscipit aliquet diam. In
								iaculis vulputate felis sit amet cursus. Vivamus odio enim,
								fermentum at ipsum a, ultrices mollis mi. Donec blandit eleifend
								pellentesque. Maecenas consequat consectetur tellus eu
								malesuada. Cras porta venenatis tempus. Pellentesque sed mauris
								id libero ullamcorper congue. Maecenas fermentum nulla lacus, a
								feugiat ante varius porttitor.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default Fiction;
