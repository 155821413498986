import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "./../../components/PageTitle";
import BodyClass from "./../../components/BodyClass";
import AutoplayVideo from "./../../components/AutoplayVideo";
import RelatedProjectsShorts from "../../components/RelatedProjectsShorts";

import ImageShortsPoster from "./../../images/cover/paprika_shorts_header_photo_desktop_alt.jpg";
import ImageShortsPosterMobile from "./../../images/cover/paprika_shorts_header_photo.jpg";

const Shorts = () => {
	return (
		<>
			<PageTitle title="Short Form Highlights" />
			<BodyClass className="page-latest-projects" />

			<section id="hero" className="section projects-hero">
				<div className="projects-hero__background">
					<AutoplayVideo
						src="/videos/paprika_shorts_header_video.mp4"
						poster={ImageShortsPoster}
						posterMobile={ImageShortsPosterMobile}
						className="services-image"
					/>
				</div>

				<Container>
					<h1>
						<mark>Short Form</mark> Highlightss
					</h1>
				</Container>
			</section>

			<section id="projects-about" className="section projects-about">
				<Container>
					<Row>
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">
								Simple. <br className="d-block d-lg-block" />
								Bold. <br className="d-block d-lg-block" />
								Creative.
							</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							<p>
								Meet Paprika Shorts - where over 20 in-house short form content
								creatives are weaving magic in the heart of Budapest! We're your
								go-to international creative hub, rocking everything from 360º
								campaigns to trailers, branded content, TV commercials, and
								social media campaigns. Whether it's tailored for streaming
								platforms, pay-TV, or commercial brands, we've got your back
								from concept to production. Let's craft your next big campaign
								together!
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="projects-gallery" className="section projects-gallery">
				<Container className="py-5" style={{ backgroundColor: "#ddd" }} fluid>
					<p className="text-center mb-0 text-black">gallery here </p>
				</Container>
			</section>

			<section
				id="projects-about-secondary"
				className="section projects-about-secondary"
			>
				<Container>
					<Row>
						<Col lg={{ span: 1, order: 1 }} className="d-none d-lg-block"></Col>

						<Col md={{ span: 4, order: 4 }}>
							<h2 className="text-center text-md-start">
								Future of brand promotion
							</h2>
						</Col>

						<Col md={{ span: 2, order: 3 }} className="d-none d-md-block"></Col>

						<Col md={{ span: 6, order: 2 }} lg={4}>
							<p>
								Short-format videos have become a dominant force in social media
								and content creation. Platforms like TikTok, Instagram Reels and
								Snapchat have revolutionized the way individuals and brands
								engage with their audiences. They offer bite-sized content that
								captures attention in seconds – not to mention there are several
								wonderful commercials that have proven you can tell stories,
								entertain audiences, and sell products with short-form content.
								However, the tight time frame is not a disadvantage, but an
								exciting challenge for us! We know how to create cohesive and
								effective campaign materials that are fast, entertaining, and
								easy to consume. We know how to tell profound, emotional,
								philosophical, or simply funny stories that are only seen for a
								minute but stay with you forever!
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<RelatedProjectsShorts />
		</>
	);
};

export default Shorts;
