import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";

import PageTitle from "../components/PageTitle";
import BodyClass from "../components/BodyClass";
import ProjectCard from "../components/ProjectCard";

const Work = () => {
	const [isDesktop, setIsDesktop] = useState(false);
	const [activeTab, setActiveTab] = useState("fiction");

	useEffect(() => {
		const isDeviceDesktop = () =>
			window.matchMedia("(min-width: 768px)").matches;

		setIsDesktop(isDeviceDesktop());

		const updateActiveTabFromHash = () => {
			const hash = window.location.hash.replace("#", "");
			if (hash) {
				setActiveTab(hash);
			}
		};

		updateActiveTabFromHash();

		window.addEventListener("hashchange", updateActiveTabFromHash);

		return () => {
			window.removeEventListener("hashchange", updateActiveTabFromHash);
		};
	}, []);

	const handleSelect = (key: string | null) => {
		if (key) {
			setActiveTab(key);
			window.history.replaceState(null, "", `#${key}`);
		}
	};

	return (
		<>
			<PageTitle title="Work" />
			<BodyClass className={["page-projects", "no-cover"]} />

			<section id="projects-tabs" className="section projects-tabs">
				<Container>
					<Tabs
						defaultActiveKey="fiction"
						id="filters"
						onSelect={handleSelect}
						activeKey={activeTab}
					>
						<Tab eventKey="fiction" title="Fiction">
							<ul className="project__list">
								<li className="project">
									<ProjectCard
										title="Tiger Daddy :newline HU, CZ"
										to="/project/tiger-daddy"
										thumbnail="/images/small/tiger_daddy.jpg"
										video="/videos/projects/tiger_daddy.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Liar"
										to="/project/liar"
										thumbnail="/images/small/liar.jpg"
										video="/videos/projects/liar.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Teacher :newline SK, HU, SI, CZ"
										to="/project/teacher"
										thumbnail="/images/small/the_teacher.jpg"
										video="/videos/projects/the_teacher.mp4"
									/>
								</li>

								{/* <li className="project">
									<ProjectCard
										title="The Teacher Hungary"
										to="/project/the-teacher-hu"
										thumbnail="/images/small/the_teacher.jpg"
										video="/videos/projects/the_teacher.mp4"
									/>
								</li> */}

								{/* <li className="project">
									<ProjectCard
										title="The Teacher Slovenia"
										to="/project/the-teacher-slo"
										thumbnail="/images/small/the_teacher.jpg"
										video="/videos/projects/the_teacher.mp4"
									/>
								</li> */}

								{/* <li className="project">
									<ProjectCard
										title="The Teacher Czechia"
										to="/project/the-teacher-cz"
										thumbnail="/images/small/the_teacher.jpg"
										video="/videos/projects/the_teacher.mp4"
									/>
								</li> */}

								{/* <li className="project">
									<ProjectCard
										title="The Teacher Slovakia"
										to="/project/the-teacher-sk"
										thumbnail="/images/small/the_teacher.jpg"
										video="/videos/projects/the_teacher.mp4"
									/>
								</li> */}

								<li className="project">
									<ProjectCard
										title="White Boss"
										to="/project/white-boss"
										thumbnail="/images/small/white_boss.jpg"
										video="/videos/projects/white_boss.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Angel's Trumpets"
										to="/project/angels-trumpets"
										thumbnail="/images/small/angels_trumpets.jpg"
										video="/videos/projects/angels_trumpets.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Murderesses"
										to="/project/murderesses"
										thumbnail="/images/small/murderesses.jpg"
										video="/videos/projects/murderesses.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Ivanovs"
										to="/project/ivanovs"
										thumbnail="/images/small/ivanovs.jpg"
										video="/videos/projects/ivanovs.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Neighbour's Cow"
										to="/project/the-neighbours-cow"
										thumbnail="/images/small/the_neighbours_cow.jpg"
										video="/videos/projects/the_neighbours_cow.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="H.P.I."
										to="/project/hpi"
										thumbnail="/images/small/hpi.jpg"
										video="/videos/projects/hpi.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="A'la Carte"
										to="/project/ala-carte"
										thumbnail="/images/small/ala_carte.jpg"
										video="/videos/projects/ala_carte.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Glorious Girls"
										to="/project/glorious-girls"
										thumbnail="/images/small/glorious_girls.jpg"
										video="/videos/projects/glorious_girls.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Land of the Brave"
										to="/project/land-of-the-brave"
										thumbnail="/images/small/land_of_the_brave.jpg"
										video="/videos/projects/land_of_the_brave.mp4"
									/>
								</li>

								{/* <li className="project">
									<ProjectCard
										title="Melodrama / Têlenovela"
										to="/project/melodrama"
										videoId=""
										accessToken=""
									/>
								</li> */}
							</ul>
						</Tab>

						<Tab
							eventKey="reality-and-entertainment"
							title="Reality & Entertainment"
						>
							<ul className="project__list">
								<li className="project">
									<ProjectCard
										title="I'm a celebrity, get me out of here!"
										to="/project/im-a-celebrity-get-me-out-of-here"
										thumbnail="/images/small/im_a_celebrity_get_me_out_of_here.jpg"
										video="/videos/projects/im_a_celebrity_get_me_out_of_here.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="1000 wonders of the world"
										to="/project/1000-wonders-of-the-world"
										thumbnail="/images/small/1000_wonders_of_the_world.jpg"
										video="/videos/projects/1000_wonders_of_the_world.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Little Chef"
										to="/project/little-chef"
										thumbnail="/images/small/little_chef.jpg"
										video="/videos/projects/little_chef.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Power Couple"
										to="/project/power-couple"
										thumbnail="/images/small/power_couple.jpg"
										video="/videos/projects/power_couple.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Lawmen"
										to="/project/the-lawmen"
										thumbnail="/images/small/the_lawmen.jpg"
										video="/videos/projects/the_lawmen.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Human to Human"
										to="/project/human-to-human"
										thumbnail="/images/small/human_to_human.jpg"
										video="/videos/projects/human_to_human.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Born on"
										to="/project/born-on"
										thumbnail="/images/small/born_on.jpg"
										video="/videos/projects/born_on.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Dancing with the Stars"
										to="/project/dancing-with-the-stars"
										thumbnail="/images/small/dancing_with_the_stars.jpg"
										video="/videos/projects/dancing_with_the_stars.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="5 Pills Away"
										to="/project/5-pills-away"
										thumbnail="/images/small/5_pils_away.jpg"
										video="/videos/projects/5_pils_away.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Paradise Hotel"
										to="/project/paradise-hotel"
										thumbnail="/images/small/paradise_hotel.jpg"
										video="/videos/projects/paradise_hotel.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Traitors"
										to="/project/traitors"
										thumbnail="/images/small/traitors.jpg"
										video="/videos/projects/traitors.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Survivor"
										to="/project/survivor"
										thumbnail="/images/small/survivor.jpg"
										video="/videos/projects/survivor.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Mad About Wheels"
										to="/project/mad-about-wheels"
										thumbnail="/images/small/mad_about_wheels.jpg"
										video="/videos/projects/mad_about_wheels.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Farmer Wants A Wife"
										to="/project/farmer-wants-a-wife"
										thumbnail="/images/small/farmer_wants_a_wife.jpg"
										video="/videos/projects/farmer_wants_a_wife.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Celebs Go Dating"
										to="/project/celebs-go-dating"
										thumbnail="/images/small/celebs_go_dating.jpg"
										video="/videos/projects/celebs_go_dating.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Wall"
										to="/project/the-wall"
										thumbnail="/images/small/the_wall.jpg"
										video="/videos/projects/the_wall.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Farm"
										to="/project/the-farm"
										thumbnail="/images/small/the_farm.jpg"
										video="/videos/projects/the_farm.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Bridge"
										to="/project/the-bridge"
										thumbnail="/images/small/the_bridge.jpg"
										video="/videos/projects/the_bridge.mp4"
									/>
								</li>

								{/* <li className="project">
									<ProjectCard
										title="Wife Swap"
										to="/project/wife-swap"
										videoId=""
										accessToken=""
									/>
								</li> */}

								<li className="project">
									<ProjectCard
										title="Fish On The Cake"
										to="/project/fish-on-the-cake"
										thumbnail="/images/small/fish_on_the_cake.jpg"
										video="/videos/projects/fish_on_the_cake.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Survivor / Robinson"
										to="/project/survivor-robinson"
										thumbnail="/images/small/robinson_s2.jpg"
										video="/videos/projects/robinson_s2.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Love Island"
										to="/project/love-island"
										thumbnail="/images/small/love_island.jpg"
										video="/videos/projects/love_island.mp4"
									/>
								</li>
							</ul>
						</Tab>

						<Tab eventKey="shorts" title="Shorts">
							<ul className="project__list">
								<li className="project">
									<ProjectCard
										title="Telekom - Helló biznisz!"
										to="/project/telekom-hello-biznisz"
										thumbnail="/images/small/telekom_hello_biznisz.jpg"
										video="/videos/projects/telekom_hello_biznisz.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Murderesses Title Sequence"
										to="/project/murderesses-title-sequence"
										thumbnail="/images/small/murderesses.jpg"
										video="/videos/projects/murderesses.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Informant. SoMe launch campaign"
										to="/project/the-informant-some-launch-campaign"
										thumbnail="/images/small/the_informant.jpg"
										video="/videos/projects/the_informant.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Footie? Now!"
										to="/project/footie-now"
										thumbnail="/images/small/footie_now.jpg"
										video="/videos/projects/footie_now.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="RTL II Channel Rebranding"
										to="/project/rtl-2-channel-rebranding"
										thumbnail="/images/small/rtl2_channel_rebrand.jpg"
										video="/videos/projects/rtl2_channel_rebrand.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Viaplay Baltic Launch campaign"
										to="/project/viaplay-baltic-launch-campaign"
										thumbnail="/images/small/viaplay_baltic_showreel.jpg"
										video="/videos/projects/viaplay_baltic_showreel.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Voyo Launch campaign & brand identity"
										to="/project/voyo-launch-campaign-and-brand-identity"
										thumbnail="/images/small/voyo_spots.jpg"
										video="/videos/projects/voyo_spots.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Canesten: Intim történetek"
										to="/project/canesten-intim-tortenetek"
										thumbnail="/images/small/canesten.jpg"
										video="/videos/projects/canesten.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Watt Energy Drink"
										to="/project/watt-energy-drink"
										thumbnail="/images/small/watt_energy.jpg"
										video="/videos/projects/watt_energy.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Husqvarna"
										to="/project/husqvarna"
										thumbnail="/images/small/husqvarna.jpg"
										video="/videos/projects/husqvarna.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Kotanyi"
										to="/project/kotanyi"
										thumbnail="/images/small/kotanyi.jpg"
										video="/videos/projects/kotanyi.mp4"
									/>
								</li>

								{/* <li className="project">
									<ProjectCard
										title="Trailer for Sony"
										to="/project/trailer-for-sony"
										videoId=""
										accessToken=""
									/>
								</li> */}
							</ul>
						</Tab>

						<Tab eventKey="production-services" title="Production services">
							<ul className="project__list">
								<li className="project">
									<ProjectCard
										title="Desperate Measures"
										to="/project/desperate-measures"
										thumbnail="/images/small/desperate_measures.jpg"
										video="/videos/projects/desperate_measures.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Hilma"
										to="/project/hilma"
										thumbnail="/images/small/hilma.jpg"
										video="/videos/projects/hilma.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Ex-wife"
										to="/project/the-ex-wife"
										thumbnail="/images/small/the_exwife.jpg"
										video="/videos/projects/the_exwife.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Love Rat"
										to="/project/love-rat"
										thumbnail="/images/small/love_rat.jpg"
										video="/videos/projects/love_rat.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Blind Spot"
										to="/project/blind-spot"
										thumbnail="/images/small/blind_spot.jpg"
										video="/videos/projects/blind_spot.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Furia"
										to="/project/furia"
										thumbnail="/images/small/furia_s2.jpg"
										video="/videos/projects/furia_s2.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Stockholm Bloodbath"
										to="/project/stockholm-bloodbath"
										thumbnail="/images/small/stockholm_bloodbath.jpg"
										video="/videos/projects/stockholm_bloodbath.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Agent Hamilton"
										to="/project/agent-hamilton"
										thumbnail="/images/small/agent_hamilton.jpg"
										video="/videos/projects/agent_hamilton.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Perfect Patient / Slutet på sommaren"
										to="/project/perfect-patient-slutet-pa-sommaren"
										thumbnail="/images/small/perfect_patient.jpg"
										video="/videos/projects/perfect_patient.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Ambassador / Ambassadøren"
										to="/project/the-ambassador-ambassadoren"
										thumbnail="/images/small/the_ambassador.jpg"
										video="/videos/projects/the_ambassador.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Max Anger"
										to="/project/max-anger"
										thumbnail="/images/small/max_anger.jpg"
										video="/videos/projects/max_anger.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Teacher UK"
										to="/project/the-teacher-uk"
										thumbnail="/images/small/the_teacher_channel_5.jpg"
										video="/videos/projects/the_teacher_channel_5.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Conspiracy of Silence"
										to="/project/conspiracy-of-silence"
										thumbnail="/images/small/conspiracy_of_silence.jpg"
										video="/videos/projects/conspiracy_of_silence.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Home Invasion"
										to="/project/home-invasion"
										thumbnail="/images/small/home_invasion.jpg"
										video="/videos/projects/home_invasion.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Vardy vs Rooney"
										to="/project/vardy-vs-rooney"
										thumbnail="/images/small/vardy_vs_rooney.jpg"
										video="/videos/projects/vardy_vs_rooney.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="The Good Ship Murder"
										to="/project/the-good-ship-murder"
										thumbnail="/images/small/the_good_ship_murder.jpg"
										video="/videos/projects/the_good_ship_murder.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Deadline"
										to="/project/deadline"
										thumbnail="/images/small/deadline.jpg"
										video="/videos/projects/deadline.mp4"
									/>
								</li>

								<li className="project">
									<ProjectCard
										title="Coma"
										to="/project/coma"
										thumbnail="/images/small/coma.jpg"
										video="/videos/projects/coma.mp4"
									/>
								</li>
							</ul>
						</Tab>
					</Tabs>
				</Container>
			</section>
		</>
	);
};

export default Work;
