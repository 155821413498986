import React from "react";

import RedirectComponent from "../../components/RedirectComponent";

const EmptyDivision = () => {
	return (
		<>
			<RedirectComponent to="/division/fiction" />
		</>
	);
};

export default EmptyDivision;
