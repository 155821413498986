import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsProductionServices from "../../components/RelatedProjectsProductionServices";

const ProjectDesperateMeasures = () => {
	return (
		<>
			<PageTitle title="Desperate Measures project" />
			<BodyClass className={["page-project", "desperate-measures"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/desperate_measures.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/desperate_measures.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Desperate Measures</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Diligent bank clerk Rowan has raised her teenage son Finn on a
								lawless estate whilst paying off her own absent father's debts.
								Rowan's life spirals out of control when Finn is coerced into a
								botched drug deal by a local gang. With Finn's life on the line
								and a violent gang leader encroaching on her life, Rowan is
								faced with recouping the money that Finn lost. Desperate to save
								her son and having exhausted all other options, Rowan commits to
								a dangerous heist at the bank she works in.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>UK</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Channel 5</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950794952"
							accessToken="3c42c26bb8"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsProductionServices title={true} />
		</>
	);
};

export default ProjectDesperateMeasures;
