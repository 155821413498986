import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "mapbox-gl/dist/mapbox-gl.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.scss";

import Layout from "./components/Layout";

import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

import EmptyDivision from "./pages/division/EmptyDivision";
import Fiction from "./pages/division/Fiction";
import RealityAndEntertainment from "./pages/division/RealityAndEntertainment";
import Shorts from "./pages/division/Shorts";
import ProductionServices from "./pages/division/ProductionServices";

import EmptyProject from "./pages/projects/EmptyProject";
import ProjectLiar from "./pages/projects/ProjectLiar";
import Project5PillsAway from "./pages/projects/Project5PillsAway";
import ProjectBornOn from "./pages/projects/ProjectBornOn";
import Project1000WondersOfTheWorld from "./pages/projects/Project1000WondersOfTheWorld";
import ProjectTheLawmen from "./pages/projects/ProjectTheLawmen";
import ProjectMadAboutWheels from "./pages/projects/ProjectMadAboutWheels";
import ProjectTelekomHelloBiznisz from "./pages/projects/ProjectTelekomHelloBiznisz";
import ProjectMurderessesTitleSequence from "./pages/projects/ProjectMurderessesTitleSequence";
import ProjectTheInformantSomeLaunchCampaign from "./pages/projects/ProjectTheInformantSomeLaunchCampaign";
import ProjectRtl2ChannelRebranding from "./pages/projects/ProjectRtl2ChannelRebranding";
import ProjectViaplayBalticLaunchCampaign from "./pages/projects/ProjectViaplayBalticLaunchCampaign";
import ProjectVoyoLaunchCampaign from "./pages/projects/ProjectVoyoLaunchCampaign";
import ProjectCanestenIntimTortenetek from "./pages/projects/ProjectCanestenIntimTortenetek";
import ProjectWattEnergyDrink from "./pages/projects/ProjectWattEnergyDrink";
import ProjectHusqvarna from "./pages/projects/ProjectHusqvarna";
import ProjectKotanyi from "./pages/projects/ProjectKotanyi";
import ProjectTrailerForSony from "./pages/projects/ProjectTrailerForSony";
import ProjectStockholmBloodbath from "./pages/projects/ProjectStockholmBloodbath";
import ProjectFuria from "./pages/projects/ProjectFuria";
import ProjectLoveRat from "./pages/projects/ProjectLoveRat";
import ProjectBlindspot from "./pages/projects/ProjectBlindspot";
import ProjectTigerDaddy from "./pages/projects/ProjectTigerDaddy";
import ProjectTheTeacherSlo from "./pages/projects/ProjectTheTeacherSlo";
import ProjectTheTeacherCz from "./pages/projects/ProjectTheTeacherCz";
import ProjectTheTeacherSk from "./pages/projects/ProjectTheTeacherSk";
import ProjectTheTeacherHu from "./pages/projects/ProjectTheTeacherHu";
import ProjectWhiteBoss from "./pages/projects/ProjectWhiteBoss";
import ProjectAngelsTrumpets from "./pages/projects/ProjectAngelsTrumpets";
import ProjectMurderesses from "./pages/projects/ProjectMurderesses";
import ProjectIvanovs from "./pages/projects/ProjectIvanovs";
import ProjectTheNeighboursCow from "./pages/projects/ProjectTheNeighboursCow";
import ProjectHpi from "./pages/projects/ProjectHpi";
import ProjectAlaCarte from "./pages/projects/ProjectAlaCarte";
import ProjectGloriousGirls from "./pages/projects/ProjectGloriousGirls";
import ProjectLandOfTheBrave from "./pages/projects/ProjectLandOfTheBrave";
import ProjectMelodrama from "./pages/projects/ProjectMelodrama";
import ProjectImACelebrityGetMeOutOfHere from "./pages/projects/ProjectImACelebrityGetMeOutOfHere";
import ProjectLittleChef from "./pages/projects/ProjectLittleChef";
import ProjectPowerCouple from "./pages/projects/ProjectPowerCouple";
import ProjectHumanToHuman from "./pages/projects/ProjectHumanToHuman";
import ProjectDancingWithTheStars from "./pages/projects/ProjectDancingWithTheStars";
import ProjectParadiseHotel from "./pages/projects/ProjectParadiseHotel";
import ProjectTraitors from "./pages/projects/ProjectTraitors";
import ProjectSurvivor from "./pages/projects/ProjectSurvivor";
import ProjectFarmerWantsAWife from "./pages/projects/ProjectFarmerWantsAWife";
import ProjectCelebsGoDating from "./pages/projects/ProjectCelebsGoDating";
import ProjectTheWall from "./pages/projects/ProjectTheWall";
import ProjectTheFarm from "./pages/projects/ProjectTheFarm";
import ProjectTheBridge from "./pages/projects/ProjectTheBridge";
import ProjectWifeSwap from "./pages/projects/ProjectWifeSwap";
import ProjectFishOnTheCake from "./pages/projects/ProjectFishOnTheCake";
import ProjectSurvivorRobinson from "./pages/projects/ProjectSurvivorRobinson";
import ProjectLoveIsland from "./pages/projects/ProjectLoveIsland";
import ProjectFootieNow from "./pages/projects/ProjectFootieNow";
import ProjectDesperateMeasures from "./pages/projects/ProjectDesperateMeasures";
import ProjectHilma from "./pages/projects/ProjectHilma";
import ProjectTheExWife from "./pages/projects/ProjectTheExWife";
import ProjectAgentHamilton from "./pages/projects/ProjectAgentHamilton";
import ProjectPerfectPatientSlutetPaSommaren from "./pages/projects/ProjectPerfectPatientSlutetPaSommaren";
import ProjectTheAmbassadorAmbassadoren from "./pages/projects/ProjectTheAmbassadorAmbassadoren";
import ProjectMaxAnger from "./pages/projects/ProjectMaxAnger";
import ProjectTheTeacherUk from "./pages/projects/ProjectTheTeacherUk";
import ProjectConspiracyOfSilence from "./pages/projects/ProjectConspiracyOfSilence";
import ProjectHomeInvasion from "./pages/projects/ProjectHomeInvasion";
import ProjectTheTeacher from "./pages/projects/ProjectTheTeacher";
import ProjectVardyVsRooney from "./pages/projects/ProjectVardyVsRooney";
import ProjectTheGoodShipMurder from "./pages/projects/ProjectTheGoodShipMurder";
import ProjectDeadline from "./pages/projects/ProjectDeadline";
import ProjectComa from "./pages/projects/ProjectComa";

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="work" element={<Work />} />
						<Route path="about" element={<About />} />
						<Route path="contact" element={<Contact />} />
						<Route path="/division" element={<EmptyDivision />} />
						<Route path="/division/fiction" element={<Fiction />} />
						<Route
							path="/division/reality-and-entertainment"
							element={<RealityAndEntertainment />}
						/>
						<Route path="/division/shorts" element={<Shorts />} />
						<Route
							path="/division/production-services"
							element={<ProductionServices />}
						/>
						<Route path="/project" element={<EmptyProject />} />
						<Route path="/project/liar" element={<ProjectLiar />} />
						<Route path="/project/teacher" element={<ProjectTheTeacher />} />
						{/* <Route
							path="/project/the-teacher-cz"
							element={<ProjectTheTeacherCz />}
						/>
						<Route
							path="/project/the-teacher-slo"
							element={<ProjectTheTeacherSlo />}
						/>
						<Route
							path="/project/the-teacher-sk"
							element={<ProjectTheTeacherSk />}
						/>
						<Route
							path="/project/the-teacher-hu"
							element={<ProjectTheTeacherHu />}
						/> */}
						<Route
							path="/project/the-teacher-uk"
							element={<ProjectTheTeacherUk />}
						/>
						<Route path="/project/white-boss" element={<ProjectWhiteBoss />} />
						<Route
							path="/project/angels-trumpets"
							element={<ProjectAngelsTrumpets />}
						/>
						<Route
							path="/project/murderesses"
							element={<ProjectMurderesses />}
						/>
						<Route path="/project/ivanovs" element={<ProjectIvanovs />} />
						<Route
							path="/project/the-neighbours-cow"
							element={<ProjectTheNeighboursCow />}
						/>
						<Route path="/project/hpi" element={<ProjectHpi />} />
						<Route path="/project/ala-carte" element={<ProjectAlaCarte />} />
						<Route
							path="/project/glorious-girls"
							element={<ProjectGloriousGirls />}
						/>
						<Route
							path="/project/land-of-the-brave"
							element={<ProjectLandOfTheBrave />}
						/>
						<Route path="/project/melodrama" element={<ProjectMelodrama />} />
						<Route
							path="/project/im-a-celebrity-get-me-out-of-here"
							element={<ProjectImACelebrityGetMeOutOfHere />}
						/>
						<Route
							path="/project/little-chef"
							element={<ProjectLittleChef />}
						/>
						<Route
							path="/project/power-couple"
							element={<ProjectPowerCouple />}
						/>
						<Route
							path="/project/human-to-human"
							element={<ProjectHumanToHuman />}
						/>
						<Route
							path="/project/dancing-with-the-stars"
							element={<ProjectDancingWithTheStars />}
						/>
						<Route
							path="/project/paradise-hotel"
							element={<ProjectParadiseHotel />}
						/>
						<Route
							path="/project/5-pills-away"
							element={<Project5PillsAway />}
						/>
						<Route path="/project/born-on" element={<ProjectBornOn />} />
						<Route
							path="/project/1000-wonders-of-the-world"
							element={<Project1000WondersOfTheWorld />}
						/>
						<Route
							path="/project/farmer-wants-a-wife"
							element={<ProjectFarmerWantsAWife />}
						/>
						<Route
							path="/project/celebs-go-dating"
							element={<ProjectCelebsGoDating />}
						/>
						<Route path="/project/the-wall" element={<ProjectTheWall />} />
						<Route path="/project/the-farm" element={<ProjectTheFarm />} />
						<Route path="/project/the-lawmen" element={<ProjectTheLawmen />} />
						<Route
							path="/project/mad-about-wheels"
							element={<ProjectMadAboutWheels />}
						/>
						<Route path="/project/traitors" element={<ProjectTraitors />} />
						<Route path="/project/survivor" element={<ProjectSurvivor />} />
						<Route path="/project/the-bridge" element={<ProjectTheBridge />} />
						<Route path="/project/wife-swap" element={<ProjectWifeSwap />} />
						<Route
							path="/project/fish-on-the-cake"
							element={<ProjectFishOnTheCake />}
						/>
						<Route
							path="/project/survivor-robinson"
							element={<ProjectSurvivorRobinson />}
						/>
						<Route
							path="/project/love-island"
							element={<ProjectLoveIsland />}
						/>
						<Route
							path="/project/telekom-hello-biznisz"
							element={<ProjectTelekomHelloBiznisz />}
						/>
						<Route
							path="/project/murderesses-title-sequence"
							element={<ProjectMurderessesTitleSequence />}
						/>
						<Route
							path="/project/the-informant-some-launch-campaign"
							element={<ProjectTheInformantSomeLaunchCampaign />}
						/>
						<Route path="/project/footie-now" element={<ProjectFootieNow />} />
						<Route
							path="/project/rtl-2-channel-rebranding"
							element={<ProjectRtl2ChannelRebranding />}
						/>
						<Route
							path="/project/viaplay-baltic-launch-campaign"
							element={<ProjectViaplayBalticLaunchCampaign />}
						/>
						<Route
							path="/project/voyo-launch-campaign-and-brand-identity"
							element={<ProjectVoyoLaunchCampaign />}
						/>
						<Route
							path="/project/canesten-intim-tortenetek"
							element={<ProjectCanestenIntimTortenetek />}
						/>
						<Route
							path="/project/watt-energy-drink"
							element={<ProjectWattEnergyDrink />}
						/>
						<Route path="/project/husqvarna" element={<ProjectHusqvarna />} />
						<Route path="/project/kotanyi" element={<ProjectKotanyi />} />
						<Route
							path="/project/trailer-for-sony"
							element={<ProjectTrailerForSony />}
						/>
						<Route
							path="/project/stockholm-bloodbath"
							element={<ProjectStockholmBloodbath />}
						/>
						<Route
							path="/project/desperate-measures"
							element={<ProjectDesperateMeasures />}
						/>
						<Route path="/project/hilma" element={<ProjectHilma />} />
						<Route path="/project/the-ex-wife" element={<ProjectTheExWife />} />
						<Route path="/project/furia" element={<ProjectFuria />} />
						<Route
							path="/project/agent-hamilton"
							element={<ProjectAgentHamilton />}
						/>
						<Route
							path="/project/perfect-patient-slutet-pa-sommaren"
							element={<ProjectPerfectPatientSlutetPaSommaren />}
						/>
						<Route
							path="/project/the-ambassador-ambassadoren"
							element={<ProjectTheAmbassadorAmbassadoren />}
						/>
						<Route path="/project/max-anger" element={<ProjectMaxAnger />} />
						<Route path="/project/love-rat" element={<ProjectLoveRat />} />
						<Route path="/project/blind-spot" element={<ProjectBlindspot />} />
						<Route
							path="/project/conspiracy-of-silence"
							element={<ProjectConspiracyOfSilence />}
						/>
						<Route
							path="/project/home-invasion"
							element={<ProjectHomeInvasion />}
						/>
						<Route
							path="/project/tiger-daddy"
							element={<ProjectTigerDaddy />}
						/>
						<Route
							path="/project/vardy-vs-rooney"
							element={<ProjectVardyVsRooney />}
						/>
						<Route
							path="/project/the-good-ship-murder"
							element={<ProjectTheGoodShipMurder />}
						/>
						<Route path="/project/deadline" element={<ProjectDeadline />} />
						<Route path="/project/coma" element={<ProjectComa />} />

						<Route path="*" element={<NoPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
