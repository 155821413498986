import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import VimeoVideo from "./VimeoVideo";
import AutoplayVideo from "./AutoplayVideo";

interface ProjectCardProps {
	title: string;
	to: string;
	videoId?: string;
	accessToken?: string;
	video?: string;
	thumbnail?: string;
	zoom?: boolean;
	debug?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
	title,
	to,
	videoId = "",
	accessToken = "",
	video = "",
	thumbnail = "",
	zoom = false,
	debug = false,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		if (window.innerWidth >= 992) {
			setIsHovered(true);
		}
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<>
			<Link
				to={to}
				className={`project__link ${zoom ? "project__link--zoom-in" : ""} ${
					isHovered || debug ? "project__link--active" : ""
				}`}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				// style={{ backgroundImage: `url(${thumbnail})` }}
			>
				{isHovered || debug ? (
					<div
						className={`project__background ${
							zoom ? "project__background--zoom-in" : ""
						}`}
					>
						<AutoplayVideo src={video} poster={thumbnail} className="video" />
					</div>
				) : (
					<div
						className={`project__background ${
							zoom ? "project__background--zoom-in" : ""
						}`}
					>
						<img src={thumbnail} alt={title} loading="lazy" />
					</div>
				)}

				{/* <h3 className="project__title">{title}</h3> */}
				<h3 className="project__title">
					{title.split(":newline").map((line, index) => (
						<React.Fragment key={index}>
							{line}
							{index < title.split(":newline").length - 1 && <br />}
						</React.Fragment>
					))}
				</h3>
			</Link>
		</>
	);
};

export default ProjectCard;
