import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsProductionServices from "../../components/RelatedProjectsProductionServices";

const ProjectLoveRat = () => {
	return (
		<>
			<PageTitle title="Love Rat project" />
			<BodyClass className={["page-project", "love-rat"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/love_rat.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/love_rat.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Love Rat</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Recently divorced Emma enjoys a whirlwind holiday romance with
								hotel proprietor Niko on the paradise island of Cyprus. When she
								realises Niko has scammed her out of her life savings she
								enlists the help of her ex to get the money back.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>UK</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Channel 5</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="985595656"
							accessToken="4732269743"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsProductionServices title={true} />
		</>
	);
};

export default ProjectLoveRat;
