import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";

import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectLiar = () => {
	return (
		<>
			<PageTitle title="Liar project" />
			<BodyClass className={["page-project", "liar"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source srcSet="/images/big/liar.jpg" media="(min-width: 992px)" />
						<img
							src="/images/small/liar_alt.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Liar</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								High school teacher accepts a date invitation from the charming
								surgeon just after the breakup of her long-term relationship.
								The next day she accuses him of drugging and raping her.
								However, his version of events is completely different.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>SLOVAKIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Markíza</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1 season, 12 episodes</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo videoId="950792535" accessToken="90daf4a4f9" />
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectLiar;
