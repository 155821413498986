import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Squash as Hamburger } from "hamburger-react";
import { ChevronDown } from "lucide-react";

import { ReactComponent as PaprikaLogo } from "./../images/paprika-studios-logo.svg";

const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const [scroll, setScroll] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);

	const toggleSubmenu = () => {
		setSubmenuOpen(!submenuOpen);
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
            if (document.body.classList.contains("no-cover")) {
                setScroll(window.scrollY > window.innerHeight * 0.05);
            } else {
                setScroll(window.scrollY > window.innerHeight * 0.8);
            }
		});
	}, []);

	return (
		<>
			<header
				className={`
                    header
                    ${scroll ? "header--has-background" : ""}
                    ${showMenu ? "header--show-menu" : ""}
                `}
			>
				<Container>
					<Row>
						<Col xs={"auto"} lg={4} className="site-logo__wrapper">
							<Link
								to="/"
								className="site-logo__link"
								onClick={() => setShowMenu(false)}
							>
								<PaprikaLogo
									aria-label="Paprika Studios"
									className="site-logo"
								/>
							</Link>
						</Col>

						<Col className="menu-toggle__wrapper">
							<Hamburger
								toggled={showMenu}
								toggle={setShowMenu}
								size={24}
								label={showMenu ? "Close navigation" : "Open navigation"}
								rounded
							/>
						</Col>

						<Col lg={8} className="navigation__wrapper">
							<nav
								className={`navigation ${showMenu ? "navigation--show" : ""}`}
							>
								<Container>
									<ul className="navigation__items">
										<li className="navigation__item">
											<NavLink
												to="/"
												className="navigation__link"
												onClick={() => setShowMenu(false)}
											>
												Home
											</NavLink>
										</li>
										<li className="navigation__item">
											<NavLink
												to="/work"
												className="navigation__link"
												onClick={() => setShowMenu(false)}
											>
												Work
											</NavLink>
										</li>
										<li
											className="navigation__item navigation__item--has-submenu"
											onClick={toggleSubmenu}
										>
											<span className="navigation__link">
												Divisions
												<ChevronDown
													className={`${submenuOpen ? "rotated" : ""}`}
												/>
											</span>

											<ul
												className={`sub-menu ${
													submenuOpen ? "sub-menu--show" : ""
												}`}
											>
												<li className="navigation__item">
													<NavLink
														to="/division/fiction"
														className="navigation__link"
														onClick={() => setShowMenu(false)}
													>
														Fiction
													</NavLink>
												</li>
												<li className="navigation__item">
													<NavLink
														to="/division/reality-and-entertainment"
														className="navigation__link"
														onClick={() => setShowMenu(false)}
													>
														Reality & Entertainment
													</NavLink>
												</li>
												<li className="navigation__item">
													<NavLink
														to="/division/shorts"
														className="navigation__link"
														onClick={() => setShowMenu(false)}
													>
														Short Form
													</NavLink>
												</li>
												<li className="navigation__item">
													<NavLink
														to="/division/production-services"
														className="navigation__link"
														onClick={() => setShowMenu(false)}
													>
														Production Services
													</NavLink>
												</li>
											</ul>
										</li>
										<li className="navigation__item">
											<NavLink
												to="/about"
												className="navigation__link"
												onClick={() => setShowMenu(false)}
											>
												About
											</NavLink>
										</li>
										<li className="navigation__item">
											<NavLink
												to="/contact"
												className="navigation__link"
												onClick={() => setShowMenu(false)}
											>
												Contact
											</NavLink>
										</li>
									</ul>
								</Container>
							</nav>
						</Col>
					</Row>
				</Container>
			</header>
		</>
	);
};

export default Header;
