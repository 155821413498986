import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

interface CountUpInViewPortProps {
	end: number;
	suffix?: string;
	duration?: number;
	delay?: number;
}

const CountUpInViewPort: React.FC<CountUpInViewPortProps> = ({
	end,
	suffix,
	duration,
	delay,
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const countUpRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
				}
			});
		});

		if (countUpRef.current) {
			observer.observe(countUpRef.current);
		}

		return () => {
			if (countUpRef.current) {
				observer.unobserve(countUpRef.current);
			}
		};
	}, []);

	return (
		<div ref={countUpRef}>
			{isVisible && (
				<CountUp end={end} suffix={suffix} duration={duration} delay={delay} />
			)}
		</div>
	);
};

export default CountUpInViewPort;
