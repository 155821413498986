import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import FacebookBox from "./FacebookBox";

import { ReactComponent as PaprikaLogo } from "./../images/paprika-studios-logo.svg";
import { ReactComponent as YoutubeIcon } from "./../images/youtube.svg";
import { ReactComponent as FacebookIcon } from "./../images/facebook.svg";
import { ReactComponent as LinkedinIcon } from "./../images/linkedin.svg";

const Footer = () => {
	return (
		<>
			<footer className="footer" id="footer">
				<Container>
					<Row>
						<Col md={6}>
							<Link to="/" className="footer-logo__link">
								<PaprikaLogo
									className="footer-logo"
									aria-label="Paprika Studios"
								/>
							</Link>
							<p>
								We are proud to produce top-quality TV shows, branded and
								digital content for demanding clients in 8 CEE countries.
							</p>
						</Col>
						{/* <Col md={2}></Col> */}
						<Col xs={6} md={2}>
							<div className="footer-menu">
								<nav className="footer-menu__navigation">
									<ul className="footer-menu__items">
										<li className="footer-menu__item">
											<Link to="/" className="footer-menu__link">
												Home
											</Link>
										</li>

										<li className="footer-menu__item">
											<Link to="/work" className="footer-menu__link">
												Work
											</Link>
										</li>

										<li className="footer-menu__item">
											<Link to="/about" className="footer-menu__link">
												About
											</Link>
										</li>

										<li className="footer-menu__item">
											<Link to="/contact" className="footer-menu__link">
												Contact
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</Col>
						<Col xs={12} md={4} className="text-md-end">
							{/* <p>Let's do it!</p> */}

							<FacebookBox />

							{/*
							<ul className="footer-social">
								<li className="footer-social__item">
									<a
										href="https://www.youtube.com/@paprikastudiosshorts9706"
										className="footer-social__link"
										target="_blank"
										rel="noreferrer"
									>
										<YoutubeIcon aria-label="Our YouTube channel" />
									</a>
								</li>

								<li className="footer-social__item">
									<a
										href="https://fb.me/Paprika.Studios.Group"
										className="footer-social__link"
										target="_blank"
										rel="noreferrer"
									>
										<FacebookIcon aria-label="Our Facebook page" />
									</a>
								</li>

								<li className="footer-social__item">
									<a
										href="https://www.linkedin.com/company/paprikastudios/"
										className="footer-social__link"
										target="_blank"
										rel="noreferrer"
									>
										<LinkedinIcon aria-label="Our LinkedIn page" />
									</a>
								</li>
							</ul>
                            */}
						</Col>
					</Row>

					<Row>
						<Col>
							<p className="footer-copyright">
								&copy; Paprika Studios {new Date().getFullYear()}
							</p>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default Footer;
