import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsRealityAndEntertainment from "../../components/RelatedProjectsRealityAndEntertainment";

const Project1000WondersOfTheWorld = () => {
	return (
		<>
			<PageTitle title="1000 wonders of the world project" />
			<BodyClass className={["page-project", "1000-wonders-of-the-world"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/1000_wonders_of_the_world.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/1000_wonders_of_the_world.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>1000 wonders of the world</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Our endless dream is to visit 1,000 of the most beautiful and
								interesting places in the world, to show how amazing the
								environment, the faces of the people, the change of colours and
								the pure freedom of the traveller are. We dream responsibly and
								our dreams come true. What are your dreams?
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>LITHUANIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>LRT</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>2</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950792129"
							accessToken="44205065ee"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsRealityAndEntertainment title={true} />
		</>
	);
};

export default Project1000WondersOfTheWorld;
