import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProjectCard from "./ProjectCard";

interface RelatedProjectsProductionServicesProps {
	title?: boolean;
}

const RelatedProjectsProductionServices: React.FC<RelatedProjectsProductionServicesProps> = ({
	title = false,
}) => {
	return (
		<>
			<section id="further-projects" className="section further-projects">
				<Container>
					{title && <h2 className="text-center">Latest Production Services</h2>}

					<ul className="project__list">
						<li className="project">
							<ProjectCard
								title="Desperate Measures"
								to="/project/desperate-measures"
								thumbnail="/images/small/desperate_measures.jpg"
								video="/videos/projects/desperate_measures.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="Hilma"
								to="/project/hilma"
								thumbnail="/images/small/hilma.jpg"
								video="/videos/projects/hilma.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="Furia"
								to="/project/furia"
								thumbnail="/images/small/furia_s2.jpg"
								video="/videos/projects/furia_s2.mp4"
							/>
						</li>
					</ul>

					<div className="text-center mt-5">
						<Link className="button" to="/work#production-services">
							See more Production Services
						</Link>
					</div>
				</Container>
			</section>
		</>
	);
};

export default RelatedProjectsProductionServices;
