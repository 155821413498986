import React, { useEffect } from "react";

interface VimeoVideoProps {
	videoId: string;
	accessToken: string;
	background?: boolean;
    title?: string;
    quality?: string;
}

const VimeoVideo: React.FC<VimeoVideoProps> = ({
	videoId,
	accessToken,
	background = false,
    title = "",
    quality = "720p",
}) => {
	useEffect(() => {
        const script = document.createElement("script");
        script.src = "";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

	const videoUrl = `${videoId}?h=${accessToken}`;
    const backgroundAttr = background ? `&background=1&quality=${quality}` : ``;
    const src = `https://player.vimeo.com/video/${videoUrl}&portrait=0&byline=0&badge=0&autopause=0&player_id=0&app_id=58479&transparent=1&dnt=1${backgroundAttr}`;

	return (
		<>
			<div className="video video--vimeo ratio ratio-16x9">
				<iframe src={src} title={title}></iframe>
			</div>
		</>
	);
};

export default VimeoVideo;
