import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsShorts from "../../components/RelatedProjectsShorts";

const ProjectTelekomHelloBiznisz = () => {
	return (
		<>
			<PageTitle title="Telekom - Helló biznisz! project" />
			<BodyClass className={["page-project", "telekom-hello-biznisz"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/telekom_hello_biznisz.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/telekom_hello_biznisz_alt.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Telekom - Helló biznisz!</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								This project required a unique combination of skills and
								experience in advertising and reality production. Telekom
								decided to abandon classic, polished TV commercials and produce
								reality-based SoMe and TV spots. As only Paprika Shorts had this
								experience in the market, Telekom's advertising agency mito
								commissioned us to produce the films. We delivered longer spots
								for digital platforms and social media as well as 30-second
								TVCs.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>mito / Telekom</span>
								</li>

								<li className="project-details__item">
									<span>Platform</span>
									<span>TV, Youtube</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950990915"
							accessToken="f7cdd03c9e"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsShorts title={true} />
		</>
	);
};

export default ProjectTelekomHelloBiznisz;
