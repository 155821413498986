import React from "react";

import { ReactComponent as FacebookIcon } from "./../images/facebook.svg";
import FacebookAvatarImage from "./../images/paprika-studios-facebook.jpg";

const FacebookBox = () => {
	return (
		<>
			<div className="facebook-box p-2 gap-3 d-inline-flex flex-nowrap align-items-center mt-3 mt-md-0">
				<a
					href="https://www.facebook.com/Paprika.Studios.Group"
					target="_blank"
					rel="nofollow noreferrer"
					className="d-block text-decoration-none"
				>
					<img
						src={FacebookAvatarImage}
						alt="Paprika Studios"
						title="Paprika Studios"
						loading="lazy"
						draggable="false"
						className="facebook-box__image img-fluid"
					/>
				</a>

				<div className="d-flex flex-column">
					<a
						href="https://www.facebook.com/Paprika.Studios.Group"
						target="_blank"
						rel="nofollow noreferrer"
						className="facebook-box__title d-block text-decoration-none fw-bold mb-1"
					>
						Paprika Studios
					</a>

					<a
						href="https://www.facebook.com/Paprika.Studios.Group"
						target="_blank"
						rel="nofollow noreferrer"
						className="button button-sm"
					>
						<FacebookIcon className="me-1" />
						Follow page
					</a>
				</div>
			</div>
		</>
	);
};

export default FacebookBox;
