import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "./../../components/PageTitle";
import BodyClass from "./../../components/BodyClass";
import AutoplayVideo from "./../../components/AutoplayVideo";
import RelatedProjectsRealityAndEntertainment from "../../components/RelatedProjectsRealityAndEntertainment";

import ImageRealityPoster from "./../../images/cover/paprika_reality_header_photo_desktop_alt.jpg";
import ImageRealityPosterMobile from "./../../images/cover/paprika_reality_header_photo.jpg";

const RealityAndEntertainment = () => {
	return (
		<>
			<PageTitle title="Reality & Entertainment Highlights" />
			<BodyClass className="page-latest-projects" />

			<section id="hero" className="section projects-hero">
				<div className="projects-hero__background">
					<AutoplayVideo
						src="/videos/paprika_reality_header_video.mp4"
						poster={ImageRealityPoster}
						posterMobile={ImageRealityPosterMobile}
						className="services-image"
					/>
				</div>

				<Container>
					<h1>
						<mark>Reality & Entertainment</mark> Highlights
					</h1>
				</Container>
			</section>

			<section id="projects-about" className="section projects-about">
				<Container>
					<Row>
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">
								Endless creativity and problem solving.
							</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							{/* <p>
								We create, produce and distribute ground-breaking,
								headline-grabbing and culturally transformative entertainment
								that steals the show across Europe! We are innovators and
								creators of television programs of all genres, from epic
								entertainment, popular game shows, through captivating TV shows
								to game-changing formats. We create compelling entertainment for
								every platform and channel, working closely with some of the
								best writers, directors, producers, performers, and presenters
								in our local offices across CEE.
							</p> */}
							<p>
								We create, produce and distribute ground-breaking,
								headline-grabbing and culturally transformative entertainment
								that steals the show across Europe. We are innovators and
								creators of television programs of all genres, from epic
								entertainment, popular game shows, through captivating TV shows
								to game-changing formats. We create compelling entertainment for
								every platform and channel, working closely with the best
								directors, producers, performers, content editors, and
								presenters in our offices across CEE.
							</p>
							<p>
								We take pride in being a dependable and responsive partner,
								deeply committed to every project we undertake. Our expertise
								lies in optimizing resources to enhance production value
								significantly. Additionally, we are recognized for our broad
								network that includes crew members, equipment rental companies,
								show runners, directors, location managers, casting agencies,
								costume suppliers, covering the entire TV profession in 360
								degrees.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="projects-gallery" className="section projects-gallery">
				<Container className="py-5" style={{ backgroundColor: "#ddd" }} fluid>
					<p className="text-center mb-0 text-black">gallery here </p>
				</Container>
			</section>

			<section
				id="projects-about-secondary"
				className="section projects-about-secondary"
			>
				<Container>
					<Row>
						<Col lg={{ span: 1, order: 1 }} className="d-none d-lg-block"></Col>

						<Col md={{ span: 4, order: 4 }}>
							<h2 className="text-center text-md-start">
								Driving force behind ideas, trends, and format development
							</h2>
						</Col>

						<Col md={{ span: 2, order: 3 }} className="d-none d-md-block"></Col>

						<Col md={{ span: 6, order: 2 }} lg={4}>
							<p>
								Founded on our passion for sharing remarkable tales, our
								curiosity about the world, and our desire to inspire change, we
								pride ourselves on the excellent quality of our programs and our
								ambitious editorial goals, applying our passion for excellence
								to a wide range of topics with global appeal. Our motto is
								simple: endless creativity and problem solving.
							</p>
							<p>
								We take pride in being a dependable and responsive partner,
								deeply committed to every project we undertake. Our expertise
								lies in optimizing resources to enhance production value
								significantly. Additionally, we are recognized for our broad
								network that includes crew members, equipment hire companies,
								writers, actors, directors, location managers, casting agencies,
								costume suppliers, and caterers.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<RelatedProjectsRealityAndEntertainment />
		</>
	);
};

export default RealityAndEntertainment;
