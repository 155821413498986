import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsShorts from "../../components/RelatedProjectsShorts";

const ProjectMurderessesTitleSequence = () => {
	return (
		<>
			<PageTitle title="Murderesses Title Sequence project" />
			<BodyClass className={["page-project", "murderesses-title-sequence"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/murderesses.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/murderesses.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Murderesses Title Sequence</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Murderesses is 6 episodes of beautifully shot, atmospheric and
								stylish Polish noir created by the gifted director and producer
								Kristoffer Rus. The opening sequence of the series was produced
								by Paprika Shorts. Every frame of the 32-second intro is a
								visual reference, a clue, a piece of the puzzle of the drama of
								how three strong women deal with the circles of violence.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>POLAND</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Viaplay</span>
								</li>

								<li className="project-details__item">
									<span>Platform</span>
									<span>Viaplay SVOD</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950991186"
							accessToken="80ad5fa436"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsShorts title={true} />
		</>
	);
};

export default ProjectMurderessesTitleSequence;
