import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsRealityAndEntertainment from "../../components/RelatedProjectsRealityAndEntertainment";

const ProjectWifeSwap = () => {
	return (
		<>
			<PageTitle title="Wife Swap project" />
			<BodyClass className={["page-project", "wife-swap"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<VimeoVideo videoId="" accessToken="" background={true} />
				</div>

				<Container>
					<h1>Wife Swap</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								The reality series in which two wives change their environment
								by swapping families and lives and see what it's like to walk a
								mile in someone else's shoes.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>ROMANIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Prima TV</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>29</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo videoId="" accessToken="" quality="1080p" />
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsRealityAndEntertainment title={true} />
		</>
	);
};

export default ProjectWifeSwap;
