import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectHpi = () => {
	return (
		<>
			<PageTitle title="H.P.I. project" />
			<BodyClass className={["page-project", "hpi"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source srcSet="/images/big/hpi.jpg" media="(min-width: 992px)" />
						<img
							src="/images/small/hpi.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>H.P.I.</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								A single mother of three with an IQ of 160, she works as a
								cleaning lady. By chance, the police see her potential and the
								head of the Life Protection Department offers her a job as a
								counsellor, which she accepts only if they help her solve the
								biggest mystery of her life. The somewhat obsessive, rule-bound
								Captain Dobler finds it hard to get along with the anarchist
								Renata who works alongside him, leading to scenes that are both
								comic and dramatic. In separate episodes of the series, the odd
								couple solve a crime, while Renáta's own case - the reason she
								joined the police force - takes a strange turn.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>RTL</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950799926"
							accessToken="1c793ab823"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectHpi;
