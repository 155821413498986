import React from "react";

import RedirectComponent from "../../components/RedirectComponent";

const EmptyProject = () => {
    return (
			<>
				<RedirectComponent to="/work" />
			</>
		);
};

export default EmptyProject;
