import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsRealityAndEntertainment from "../../components/RelatedProjectsRealityAndEntertainment";

const ProjectDancingWithTheStars = () => {
	return (
		<>
			<PageTitle title="Dancing with the Stars project" />
			<BodyClass className={["page-project", "dancing-with-the-stars"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/dancing_with_the_stars.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/dancing_with_the_stars.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Dancing with the Stars</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								The queen of the shiny floor shows! Dancing with the Stars is
								the worldwide known dance contest show in which celebrities
								partner with professional dancers to compete in mainly ballroom,
								Latin and free style dances.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>TV2</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>4</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="957931028"
							accessToken="051a294c9f"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsRealityAndEntertainment title={true} />
		</>
	);
};

export default ProjectDancingWithTheStars;
