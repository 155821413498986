import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsShorts from "../../components/RelatedProjectsShorts";

const ProjectCanestenIntimTortenetek = () => {
	return (
		<>
			<PageTitle title="Canesten: Intim történetek project" />
			<BodyClass className={["page-project", "canesten-intim-tortenetek"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/canesten.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/canesten_alt.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Canesten: Intim történetek</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Bayer, the pharmaceutical company, asked us to create a series
								of short animated stories based on real-life scenarios that
								their customers could relate to. We developed the characters and
								storylines and produced four animated films.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Bayer</span>
								</li>

								<li className="project-details__item">
									<span>Platform</span>
									<span>online, TV, roadshow</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950993478"
							accessToken="cd2b7786a9"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsShorts title={true} />
		</>
	);
};

export default ProjectCanestenIntimTortenetek;
