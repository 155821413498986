import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsShorts from "../../components/RelatedProjectsShorts";

const ProjectTheInformantSomeLaunchCampaign = () => {
	return (
		<>
			<PageTitle title="The Informant. SoMe launch campaign project" />
			<BodyClass
				className={["page-project", "the-informant-some-launch-campaign"]}
			/>

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/the_informant.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/the_informant.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>The Informant. SoMe launch campaign</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								SkyShowtime launched a series of original productions on their
								platform. They asked us to conceptualise and produce this cult
								HBO series. It was a complicated communications challenge as the
								series was originally launched on HBO and sold to SkyShowtime
								shortly after launch. The goal was to communicate to viewers
								that The Informant was now on SkyShowtime. The concept was that
								halfway through the story, the actors would stop playing their
								characters and start acting like themselves, explaining that
								they're here to promote The Informant on SkyShowtime.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>SkyShowtime</span>
								</li>

								<li className="project-details__item">
									<span>Platform</span>
									<span>SkyShowtime online</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950991533"
							accessToken="261349dcdb"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still 01"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 02"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 03"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still 04"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsShorts title={true} />
		</>
	);
};

export default ProjectTheInformantSomeLaunchCampaign;
