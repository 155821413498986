import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectTheTeacher = () => {
	return (
		<>
			<PageTitle title="Teacher SK, HU, SI, CZ project" />
			<BodyClass className={["page-project", "teacher"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/the_teacher.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/the_teacher.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>
						Teacher <br /> SK, HU, SI, CZ
					</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								A charismatic professor with heart and "not so classical"
								teaching methods helps his students alongside theirs vital
								issues and questions.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>SLOVAKIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Markíza</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>5 seasons, 58 episodes</span>
								</li>
							</ul>

							<ul className="project-details mt-5">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>RTL HU</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>4 seasons, 38 episodes</span>
								</li>
							</ul>

							<ul className="project-details mt-5">
								<li className="project-details__item">
									<span>Country</span>
									<span>SLOVENIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>PoP SL</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>2 seasons, 20 episodes</span>
								</li>
							</ul>

							<ul className="project-details mt-5">
								<li className="project-details__item">
									<span>Country</span>
									<span>CZECH REPUBLIC</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Voyo/Nova CZ</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>2 seasons, 24 episodes</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="956460372"
							accessToken="52e5ac2bfb"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectTheTeacher;
