import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "./../../components/PageTitle";
import BodyClass from "./../../components/BodyClass";
import AutoplayVideo from "./../../components/AutoplayVideo";
import RelatedProjectsProductionServices from "../../components/RelatedProjectsProductionServices";

import ImageServicesPoster from "./../../images/cover/paprika_services_header_photo_desktop_alt.jpg";
import ImageServicesPosterMobile from "./../../images/cover/paprika_services_header_photo.jpg";

const ProductionServices = () => {
	return (
		<>
			<PageTitle title="Production Service Highlights" />
			<BodyClass className="page-latest-projects" />

			<section id="hero" className="section projects-hero">
				<div className="projects-hero__background">
					<AutoplayVideo
						src="/videos/paprika_services_header_video.mp4"
						poster={ImageServicesPoster}
						posterMobile={ImageServicesPosterMobile}
						className="services-image"
					/>
				</div>

				<Container>
					<h1>
						<mark>Production Service</mark> Highlights
					</h1>
				</Container>
			</section>

			<section id="projects-about" className="section projects-about">
				<Container>
					<Row>
						<Col lg={1} className="d-none d-lg-block"></Col>

						<Col md={5}>
							<h2 className="text-center text-md-start">
								Technology advances, ideas change, but great productions never
								go out of fashion
							</h2>
						</Col>

						<Col md={1} className="d-none d-md-block"></Col>

						<Col md={6} lg={4}>
							{/*
							<p>
								We are the only production company in the CEE market with operations in 8 countries, offering true one-stop production services to EU and overseas producers looking for market rates, film financing and locations. This is not only extremely practical but also makes us the most cost-effective solution for all your needs. Our highly experienced crews, tax rebates up to 45% and the professionals taking complicated administration off our clients’ shoulders ensure outstanding production value for both high and low budget projects.
							</p>
							<p>
								With a highly skilled and experienced crew, extensive knowledge
								of locations in CEE countries and production facilities not
								available to our American, Western European, or Scandinavian
								clients at home, we produce feature films and TV series in
								Eastern Europe to the same high standards expected in these
								countries.
							</p>
                            */}
							<p>
								We are the only production company in the CEE market with
								operations in 8 countries, offering true one-stop production
								services to EU and overseas producers looking for market rates,
								film financing and locations. This is not only extremely
								practical but also makes us the most cost-effective solution for
								all your needs. Our highly experienced crews, tax rebates up to
								45% and the professionals taking complicated administration off
								our clients’ shoulders ensure outstanding production value for
								both high and low budget projects.
							</p>
							<p>
								With a highly skilled and experienced crew, extensive knowledge
								of locations in CEE countries and production facilities not
								available or too expensive for our American, Western European,
								or Scandinavian clients at home, we produce feature films and TV
								series in CEE at the highest standards expected by our clients.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="projects-gallery" className="section projects-gallery">
				<Container className="py-5" style={{ backgroundColor: "#ddd" }} fluid>
					<p className="text-center mb-0 text-black">gallery here </p>
				</Container>
			</section>

			<section
				id="projects-about-secondary"
				className="section projects-about-secondary"
			>
				<Container>
					<Row>
						<Col lg={{ span: 1, order: 1 }} className="d-none d-lg-block"></Col>

						<Col md={{ span: 4, order: 4 }}>
							<h2 className="text-center text-md-start">
								Figures speak for themselves
							</h2>
						</Col>

						<Col md={{ span: 2, order: 3 }} className="d-none d-md-block"></Col>

						<Col md={{ span: 6, order: 2 }} lg={4}>
							<p>
								In the last 7 years we have completed 27 productions in 3
								countries on behalf of clients. Unlike most production
								companies, we also produce content developed in-house for our
								own markets. Therefore, not only do we understand our clients'
								production and financing challenges, but we also provide them
								with fast, responsive, cost-efficient, and trustworthy services.
								Our goal is to meet our client’s expectations from the first
								inquiry to the final delivery. Our work defines us.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<RelatedProjectsProductionServices />
		</>
	);
};

export default ProductionServices;
