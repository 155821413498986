import React, { useEffect, useState } from "react";

interface AutoplayVideoProps {
	src: string;
	poster?: string;
    posterMobile?: string;
	className?: string;
}

const AutoplayVideo: React.FC<AutoplayVideoProps> = ({
	src,
	poster = "",
    posterMobile = "",
	className = "",
}) => {
	const [isDesktop, setIsDesktop] = useState<boolean>(false);

	useEffect(() => {
		const isDeviceDesktop = (): boolean =>
			window.matchMedia("(min-width: 768px)").matches;

		setIsDesktop(isDeviceDesktop());
	}, []);

	return (
		<>
			{isDesktop ? (
				<video autoPlay muted loop poster={poster} className={className}>
					<source src={src} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			) : (
				<picture>
					<source srcSet={posterMobile} />
					<source media="(min-width: 768px)" srcSet={poster} />
					<img
						src={poster}
						className={`img-fluid ${className || ""}`}
						alt="Video Background"
						aria-hidden="true"
					/>
				</picture>
			)}
		</>
	);
};

export default AutoplayVideo;
