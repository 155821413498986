import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProjectCard from "./ProjectCard";

interface RelatedProjectsFictionProps {
	title?: boolean;
}

const RelatedProjectsFiction: React.FC<RelatedProjectsFictionProps> = ({
	title = false,
}) => {
	return (
		<>
			<section id="further-projects" className="section further-projects">
				<Container>
					{title && <h2 className="text-center">Latest Fiction</h2>}

					<ul className="project__list">
						<li className="project">
							<ProjectCard
								title="Teacher SK, HU, Sl, CZ"
								to="/project/teacher"
								thumbnail="/images/small/the_teacher.jpg"
								video="/videos/projects/the_teacher.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="Murderesses"
								to="/project/murderesses"
								thumbnail="/images/small/murderesses.jpg"
								video="/videos/projects/murderesses.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="H.P.I."
								to="/project/hpi"
								thumbnail="/images/small/hpi.jpg"
								video="/videos/projects/hpi.mp4"
							/>
						</li>
					</ul>

					<div className="text-center mt-5">
						<Link className="button" to="/work#fiction">
							See more Fiction
						</Link>
					</div>
				</Container>
			</section>
		</>
	);
};

export default RelatedProjectsFiction;
