import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectTigerDaddy = () => {
	return (
		<>
			<PageTitle title="Tiger Daddy project" />
			<BodyClass className={["page-project", "tiger-daddy"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/tiger_daddy.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/tiger_daddy_alt.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Tiger Daddy <br /> HU, CZ</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								Péter (Péter Scherer), a single father, has dedicated his whole
								life to raising his three daughters as a stepfather. Now that
								Vivien (Rujder Vivien), Laura (Sára Schmidt) and Szandra (Michl
								Juli) are flying out and starting an independent life with their
								partners, Péter is also struck by the wind of freedom, and in
								the spirit of "the fifty is the new thirty" he himself begins a
								determined search for a partner.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>RTL</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>3</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950791917"
							accessToken="3c49302805"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectTigerDaddy;
