import React, { useRef, useEffect, useState } from "react";

import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import mapboxgl from "mapbox-gl";

import PageTitle from "../components/PageTitle";
import BodyClass from "../components/BodyClass";

const Contact = () => {
	const [isDesktop, setIsDesktop] = useState(false);

	const mapContainer = useRef<HTMLDivElement>(null);
	const mapBoxAccesToken: string = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;
	const mapBoxStyle: string = process.env.REACT_APP_MAPBOX_STYLE_URL!;

	useEffect(() => {
		const isDeviceDesktop = () =>
			window.matchMedia("(min-width: 768px)").matches;

		setIsDesktop(isDeviceDesktop());

		mapboxgl.accessToken = mapBoxAccesToken;

		if (mapContainer.current) {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapBoxStyle,
				center: [19.0308121, 47.5295636],
				interactive: false,
				zoom: isDeviceDesktop() ? 4 : 3.5,
				maxZoom: 4,
			});

			const cities = [
				{
					id: "budapest",
					coordinates: [19.0308121, 47.5295636],
				},
				{
					id: "bratislava",
					coordinates: [17.5012, 48.317],
				},
				{
					id: "bucharest",
					coordinates: [26.1015, 44.4391],
				},
				{
					id: "ljubljana",
					coordinates: [14.5088, 46.0488],
				},
				{
					id: "prague",
					coordinates: [14.423, 50.0862],
				},
				{
					id: "sofia",
					coordinates: [23.324, 42.6969],
				},
				{
					id: "sofia",
					coordinates: [23.324, 42.6969],
				},
				{
					id: "vilnius",
					coordinates: [25.2837, 54.688],
				},
				{
					id: "warsaw",
					coordinates: [21.0086, 52.2307],
				},
			];

			for (const city of cities) {
				const el = document.createElement("div");
				el.className = "map__marker";
				el.classList.add("map__marker--" + city.id);

				const ll = new mapboxgl.LngLat(
					city.coordinates[0],
					city.coordinates[1],
				);

				new mapboxgl.Marker(el).setLngLat(ll).addTo(map);
			}

			map.scrollZoom.disable();

			return () => map.remove();
		}
	}, []);

	const [formInput, setFormInput] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [submitting, setSubmitting] = useState(false);
	const [message, setMessage] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);

	const handleInput = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setFormInput({
			...formInput,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setSubmitting(true);

		const formData = new FormData();
		formData.append("name", formInput.name);
		formData.append("email", formInput.email);
		formData.append("message", formInput.message);

		axios({
			url: process.env.REACT_APP_MAIL_DOMAIN + "/send_mail.php",
			method: "post",
			data: formData,
		})
			.then((response) => {
				if (response.data.response === 1) {
					setIsSuccess(true);
					setMessage("Thank you for contacting Paprika Studios! We'll get back to you as soon as we can!");
				} else {
					setIsSuccess(false);
					setMessage("Failed to send message.");
				}
			})
			.catch((error) => {
				console.error("Error sending message", error);
				setIsSuccess(false);
				setMessage("Error sending message.");
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<>
			<PageTitle title="Contact" />
			<BodyClass className="page-contact" />

			<section id="contact" className="section contact text-center">
				<Container>
					<h1>Feel free to get in touch with us!</h1>

					<div className="contact-form__wrapper">
						<form className="contact-form" onSubmit={handleSubmit}>
							<div className="form-group">
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									placeholder="Your name"
									onChange={handleInput}
									value={formInput.name}
									required
								/>
								<label htmlFor="name">Your name</label>
							</div>

							<div className="form-group">
								<input
									type="email"
									id="email"
									name="email"
									className="form-control"
									placeholder="E-mail"
									onChange={handleInput}
									value={formInput.email}
									required
								/>
								<label htmlFor="email">E-mail</label>
							</div>

							<div className="form-group">
								<textarea
									id="message"
									name="message"
									rows={6}
									className="form-control"
									placeholder="Your message"
									onChange={handleInput}
									value={formInput.message}
									required
								></textarea>
								<label htmlFor="message">Your message</label>
							</div>

							<button
								type="submit"
								className={`button button--secondary button--submit ${
									submitting ? "is-loading" : isSuccess ? "success" : ""
								}`}
								disabled={submitting}
							>
								{submitting ? "Sending..." : "Send Message"}
							</button>

							{message && (
								<div className={`message ${isSuccess ? "success" : "error"}`}>
									{message}
								</div>
							)}
						</form>
					</div>
				</Container>
			</section>

			<section id="locations" className="section locations">
				<Container>
					<div className="map__wrapper">
						<div className="map" ref={mapContainer} />
					</div>

					<ul className="office__list">
						<li className="office">
							<h3 className="office__city">Bratislava</h3>
							<div className="office__address">Ladova 7, 811 05 Bratislava</div>
						</li>

						<li className="office">
							<h3 className="office__city">Bucharest</h3>
							<div className="office__address">
								Strada Dr. Gen. Emanoil Severin no. 2, ap 3, 1st floor
							</div>
						</li>

						<li className="office">
							<h3 className="office__city">Budapest</h3>
							<div className="office__address">Montevideo utca 2/B, 1037</div>
						</li>

						<li className="office">
							<h3 className="office__city">Ljubljana</h3>
							<div className="office__address">
								Ljubljana, CELOVSKA CESTA 43
							</div>
						</li>

						<li className="office">
							<h3 className="office__city">Prague</h3>
							<div className="office__address">Praga Italska 1203/18 12000</div>
						</li>

						<li className="office">
							<h3 className="office__city">Sofia</h3>
							<div className="office__address">
								23 Tzar Osvoboditel Blvd., 1504 Sofia
							</div>
						</li>

						<li className="office">
							<h3 className="office__city">Vilnius</h3>
							<div className="office__address">
								P. Luksio str. 5b, Vilnius, LT08221
							</div>
						</li>

						<li className="office">
							<h3 className="office__city">Warsaw</h3>
							<div className="office__address">
								00-735 Warszawa ul. Iwicka 1/3 lok. 7
							</div>
						</li>
					</ul>
				</Container>
			</section>

			{/*
			<Container style={{ marginTop: "8rem" }}>
				<h1>Sample Contact Form</h1>
				<pre>{JSON.stringify(formInput, null, 1)}</pre>

				<form onSubmit={handleSubmit}>
					<label htmlFor="name">
						Name: <br />
						<input
							type="text"
							name="name"
							id="name"
							value={formInput.name}
							onChange={handleInput}
						/>
					</label>

					<br />

					<label htmlFor="email">
						email: <br />
						<input
							type="email"
							name="email"
							id="email"
							value={formInput.email}
							onChange={handleInput}
						/>
					</label>

					<br />

					<label htmlFor="message">
						message: <br />
						<textarea
							name="message"
							id="message"
							value={formInput.message}
							onChange={handleInput}
						></textarea>
					</label>

					<br />

					<button type="submit">Send Mail</button>
				</form>
			</Container>
            */}
		</>
	);
};

export default Contact;
