import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProjectCard from "./ProjectCard";

interface RelatedProjectsRealityAndEntertainmentProps {
	title?: boolean;
}

const RelatedProjectsRealityAndEntertainment: React.FC<RelatedProjectsRealityAndEntertainmentProps> = ({
	title = false,
}) => {
	return (
		<>
			<section id="further-projects" className="section further-projects">
				<Container>
					{title && <h2 className="text-center">Latest Reality</h2>}

					<ul className="project__list">
						<li className="project">
							<ProjectCard
								title="Dancing with the Stars"
								to="/project/dancing-with-the-stars"
								thumbnail="/images/small/dancing_with_the_stars.jpg"
								video="/videos/projects/dancing_with_the_stars.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="Paradise Hotel"
								to="/project/paradise-hotel"
								thumbnail="/images/small/paradise_hotel.jpg"
								video="/videos/projects/paradise_hotel.mp4"
							/>
						</li>

						<li className="project">
							<ProjectCard
								title="Traitors"
								to="/project/traitors"
								thumbnail="/images/small/traitors.jpg"
								video="/videos/projects/traitors.mp4"
							/>
						</li>
					</ul>

					<div className="text-center mt-5">
						<Link className="button" to="/work#reality-and-entertainment">
							See more Reality
						</Link>
					</div>
				</Container>
			</section>
		</>
	);
};

export default RelatedProjectsRealityAndEntertainment;
