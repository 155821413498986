import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const RedirectComponent = ({ to }: { to: string }) => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(to);
	}, [navigate, to]);

	return null;
};

export default RedirectComponent;
