import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = ({ title }: { title: any }) => {
    const location = useLocation();

    useEffect(() => {
        document.title = title + " – Paprika Studios";
    }, [location, title]);

    return null;
}

export default PageTitle;
