import React from "react";

import { Container } from "react-bootstrap";
import PageTitle from "../components/PageTitle";
import BodyClass from "../components/BodyClass";
import { Link } from "react-router-dom";

const NoPage = () => {
	return (
		<>
			<PageTitle title="Not found" />
			<BodyClass className={["page-not-found", "no-cover"]} />

			<Container style={{ marginTop: "16rem" }}>
				<h1>
					Ooops... <br className="d-none d-lg-block" /> Page is missing
				</h1>
				<p>
					We are sorry, but the page you are looking for does not exist. Please
					check the entered address and try again.
				</p>

				<ul className="button-list">
					<li>
						<Link to="/" className="button">
							Home
						</Link>
					</li>
					<li>
						<Link to="/about" className="button">
							About
						</Link>
					</li>
					<li>
						<Link to="/contact" className="button">
							Contact
						</Link>
					</li>
				</ul>
			</Container>
		</>
	);
};

export default NoPage;
