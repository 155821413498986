import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectAlaCarte = () => {
	return (
		<>
			<PageTitle title="A'la Carte project" />
			<BodyClass className={["page-project", "ala-carte"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet="/images/big/ala_carte.jpg"
							media="(min-width: 992px)"
						/>
						<img
							src="/images/small/ala_carte.jpg"
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>A'la Carte</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								It is a well-known fact that you should never change a winning
								team! Andras and Eva were a winning team. A brilliant chef and
								an experienced manager created one of the best restaurants in
								the country, and the word spread across the country. The couple
								became a true gastronomic legend, and the restaurant André won
								the highest gastronomic awards in a row. What if a third person
								enters the couple's life and not only the Lake Balaton
								Highlands' idyll is disturbed, but also their lives? Will the
								chef, who has broken all the rules, be able to take revenge and
								topple André's throne, which he himself has been sitting on?
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>HUNGARY</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Sony</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo
							videoId="950801643"
							accessToken="7f3b563161"
							quality="1080p"
						/>
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectAlaCarte;
