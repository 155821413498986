import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../components/PageTitle";
import BodyClass from "../components/BodyClass";
import SectionHypeWriter from "../components/SectionHypeWriter";

import { ReactComponent as AxnLogo } from "./../images/partners/axn.svg";
import { ReactComponent as CbsLogo } from "./../images/partners/cbs.svg";
import { ReactComponent as CmeLogo } from "./../images/partners/cme.svg";
import { ReactComponent as DiscoveryLogo } from "./../images/partners/discovery.svg";
import { ReactComponent as DisneyPlusLogo } from "./../images/partners/disney-plus.svg";
import { ReactComponent as DisneyLogo } from "./../images/partners/disney.svg";
import { ReactComponent as HboLogo } from "./../images/partners/hbo.svg";
import { ReactComponent as HbomaxLogo } from "./../images/partners/hbomax.svg";
import { ReactComponent as LrtLtLogo } from "./../images/partners/lrt-lt.svg";
import { ReactComponent as MarkizaLogo } from "./../images/partners/markiza.svg";
import { ReactComponent as NetflixLogo } from "./../images/partners/netflix.svg";
import { ReactComponent as ParamountPlusLogo } from "./../images/partners/paramount-plus.svg";
import { ReactComponent as PopLogo } from "./../images/partners/pop.svg";
import { ReactComponent as PrimeVideoLogo } from "./../images/partners/prime-video.svg";
import { ReactComponent as ProTvLogo } from "./../images/partners/pro-tv.svg";
import { ReactComponent as RtlLogo } from "./../images/partners/rtl.svg";
import { ReactComponent as SonyLogo } from "./../images/partners/sony.svg";
import { ReactComponent as Tv2Logo } from "./../images/partners/tv2.svg";
import { ReactComponent as ViaplayLogo } from "./../images/partners/viaplay.svg";
import { ReactComponent as VoyoLogo } from "./../images/partners/voyo.svg";

import AntennaGroupImage from "./../images/partners/antenna-group.png";
import ClapperboardStudiosImage from "./../images/partners/clapperboardstudios.png";
import HarmonicaFilmsImage from "./../images/partners/harmonica-films.png";
import StriveStoriesImage from "./../images/partners/strive-stories.png";

import AkosErdosImage from "./../images/team/akos-erdos.png";
import AncaIureaImage from "./../images/team/anca-iurea.png";
import AndrasJuhaszImage from "./../images/team/andras-juhasz.png";
import AstaMeskieneImage from "./../images/team/asta-meskiene.png";
import AttilaKristofImage from "./../images/team/attila-kristof.png";
import BelaNadasImage from "./../images/team/bela-nadas.png";
import BenceTrunkoImage from "./../images/team/bence-trunko.png";
import ChristopheBrackxImage from "./../images/team/christophe-brackx.png";
import GaborMatayImage from "./../images/team/gabor-matay.png";
import GaborPetoImage from "./../images/team/gabor-peto.png";
import GabrielaKostalImage from "./../images/team/gabriela-kostal.png";
import KristofDombovaryImage from "./../images/team/kristof-dombovary.png";
import KristofferRusImage from "./../images/team/kristoffer-rus.png";
import PeterRadayImage from "./../images/team/peter-raday.png";
import SpelaSmidImage from "./../images/team/spela-smid.png";
import SvetlanaStoichkovaImage from "./../images/team/svetlana-stoichkova.png";
import VeronikaJakubcovaImage from "./../images/team/veronika-jakubcova.png";

const About = () => {
	return (
		<>
			<PageTitle title="About us" />
			<BodyClass className={["page-about", "no-cover"]} />

			{/*
                <span className="ellipse ellipse--1" aria-hidden="true"></span>
                <span className="ellipse ellipse--2" aria-hidden="true"></span>
                <span className="ellipse ellipse--3" aria-hidden="true"></span>
            */}

			<section id="about-intro" className="section about-intro">
				<Container>
					<h1>17 years of excellence</h1>

					<Row>
						<Col lg={4}></Col>

						<Col md={8}>
							<p>
								Paprika Studios Group is one of the most important production
								companies in Central Europe, Eastern Europe and the Baltics,
								operatating from it's Budapest headquarters. The company
								operates as an independent production house.
							</p>
							<p>
								The long-time frontrunner company produces and develops
								entertainment shows, realities, and fiction series for more than
								30 broadcasters in 17 different languages with over 600 hours of
								materials produced every year. The variety of entertainment
								contents ranges from reality shows, shiny floor shows to dramas,
								fictions, and game shows - not to mention formats partly or
								fully developed in-house. The company not only produces and
								develops entertaining materials for TV channels but also for
								streaming platforms.
							</p>

							<h2 className="mt-5">Our Vision</h2>

							<p>
								To be a leading international content and production house,
								striving for excellence in producing and telling creative,
								innovative and original stories, a home for passionate
								professionals with a versatile approach to ideas and audiences
								in different territories and cultures.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="partners" className="section partners">
				<Container>
					<h3 className="text-center">
						Find Paprika Studios content on these platforms
					</h3>

					<ul className="partner-logo__list">
						<li className="partner-logo">
							<img
								src={AntennaGroupImage}
								alt="Antenna Group"
								title="Antenna Group"
								loading="lazy"
								draggable="false"
							/>
						</li>
						<li className="partner-logo">
							<AxnLogo title="AXN" aria-label="AXN" />
						</li>
						<li className="partner-logo">
							<CbsLogo title="CBS" aria-label="CBS" />
						</li>
						<li className="partner-logo">
							<img
								src={ClapperboardStudiosImage}
								alt="Clapperboard Studios"
								title="Clapperboard Studios"
								loading="lazy"
								draggable="false"
							/>
						</li>
						<li className="partner-logo">
							<CmeLogo
								title="Central European Media Enterprises"
								aria-label="Central European Media Enterprises"
							/>
						</li>
						<li className="partner-logo">
							<DiscoveryLogo title="Discovery" aria-label="Discovery" />
						</li>
						<li className="partner-logo">
							<DisneyPlusLogo title="Disney Plus" aria-label="Disney Plus" />
						</li>
						<li className="partner-logo">
							<DisneyLogo title="Disney" aria-label="Disney" />
						</li>
						<li className="partner-logo">
							<img
								src={HarmonicaFilmsImage}
								alt="Harmonica Films"
								title="Harmonica Films"
								loading="lazy"
								draggable="false"
							/>
						</li>
						<li className="partner-logo">
							<HboLogo title="HBO" aria-label="HBO" />
						</li>
						<li className="partner-logo">
							<HbomaxLogo title="HBO Max" aria-label="HBO Max" />
						</li>
						<li className="partner-logo">
							<LrtLtLogo title="LRT LT" aria-label="LRT LT" />
						</li>
						<li className="partner-logo">
							<MarkizaLogo title="Markíza" aria-label="Markíza" />
						</li>
						<li className="partner-logo">
							<NetflixLogo title="Netflix" aria-label="Netflix" />
						</li>
						<li className="partner-logo">
							<ParamountPlusLogo title="Paramount+" aria-label="Paramount+" />
						</li>
						<li className="partner-logo">
							<PopLogo title="Pop" aria-label="Pop" />
						</li>
						<li className="partner-logo">
							<PrimeVideoLogo title="Prime Video" aria-label="Prime Video" />
						</li>
						<li className="partner-logo">
							<ProTvLogo title="Pro TV" aria-label="Pro TV" />
						</li>
						<li className="partner-logo">
							<RtlLogo title="RTL" aria-label="RTL" />
						</li>
						<li className="partner-logo">
							<SonyLogo title="Sony" aria-label="Sony" />
						</li>
						<li className="partner-logo">
							<img
								src={StriveStoriesImage}
								alt="Strive Stories"
								title="Strive Stories"
								loading="lazy"
								draggable="false"
							/>
						</li>
						<li className="partner-logo">
							<Tv2Logo title="TV2" aria-label="TV2" />
						</li>
						<li className="partner-logo">
							<ViaplayLogo title="Viaplay" aria-label="Viaplay" />
						</li>
						<li className="partner-logo">
							<VoyoLogo title="Voyo" aria-label="Voyo" />
						</li>
					</ul>
				</Container>
			</section>

			<section id="team" className="section team">
				<Container>
					<Row>
						<Col md={2} lg={3}></Col>
						<Col md={8} lg={6}>
							<h2 className="text-center">Our Team</h2>
							<p>
								Our management team strives to ensure the highest possible
								quality of content development and production in 8 countries to
								make sure that we get closer to our long termw vision day by
								day.
							</p>
						</Col>
						<Col md={2} lg={3}></Col>
					</Row>

					<Row>
						<Col md={12}>
							<ul className="team-member__list">
								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={AkosErdosImage}
										alt="Ákos Erdős, CEO"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Ákos Erdős</strong>
									<small>CEO</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={BenceTrunkoImage}
										alt="Bence Trunkó, Operations"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Bence Trunkó</strong>
									<small>Operations</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={KristofDombovaryImage}
										alt="Kristóf Dombóváry, Production"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Kristóf Dombóváry</strong>
									<small>Production</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={GaborMatayImage}
										alt="Gábor Mátay, CFO"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Gábor Mátay</strong>
									<small>CFO</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={BelaNadasImage}
										alt="Béla Nádas, Legal Affairs"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Béla Nádas</strong>
									<small>Legal Affairs</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={PeterRadayImage}
										alt="Péter Ráday, Fiction Production"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Péter Ráday</strong>
									<small>Fiction Production</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={AndrasJuhaszImage}
										alt="András Juhász, Service Productions"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>András Juhász</strong>
									<small>Service Productions</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={GaborPetoImage}
										alt="Gábor Pető, Entertainment Production"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Gábor Pető</strong>
									<small>Entertainment Production</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={AttilaKristofImage}
										alt="Attila Kristóf, Short Form"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Attila Kristóf</strong>
									<small>Short Form</small>
								</li>
							</ul>

							<ul className="team-member__list">
								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={SvetlanaStoichkovaImage}
										alt="Svetlana Stoichkova, Office Manager, BULGARIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Svetlana Stoichkova</strong>
									<small>BULGARIA</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={GabrielaKostalImage}
										alt="Gabriela Košťál, Country Manager, CZECH REPUBLIC"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Gabriela Košťál</strong>
									<small>CZECH REPUBLIC</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={AstaMeskieneImage}
										alt="Asta Meskiene, Office Manager, LITHUANIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Asta Meskiene</strong>
									<small>LITHUANIA</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={KristofferRusImage}
										alt="Kristoffer Rus, Country Manager, POLAND"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Kristoffer Rus</strong>
									<small>POLAND</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={AncaIureaImage}
										alt="Anca Iurea, Head of Production, ROMANIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Anca Iurea</strong>
									<small>ROMANIA</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={ChristopheBrackxImage}
										alt="Christophe Brackx, Country Manager, ROMANIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Christophe Brackx</strong>
									<small>ROMANIA</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={VeronikaJakubcovaImage}
										alt="Veronika Jakubcova, Country Manager, SLOVAKIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Veronika Jakubcova</strong>
									<small>SLOVAKIA</small>
								</li>

								<li className="team-member">
									<img
										className="team-member__image img-fluid"
										src={SpelaSmidImage}
										alt="Spela Smid, Country Manager, SLOVENIA"
										loading="lazy"
										width={180}
										height={180}
										draggable="false"
									/>
									<strong>Spela Smid</strong>
									<small>SLOVENIA</small>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="join" className="section join d-none">
				<Container>
					<Row>
						<Col lg={1}></Col>

						<Col lg={5}>
							<h2 className="text-center text-md-start">
								More than just a workplace
							</h2>
						</Col>

						<Col lg={5}>
							<p>
								Join the team! We believe that work should be fun and rewarding,
								both financially and emotionally. Our corporate culture is
								simple: we want people to feel valued and recognized - on both
								sides of the camera.
							</p>
							<p className="mb-0 mt-5 text-center text-lg-end">
								<Link to="/contact" className="button">
									Contact us!
								</Link>
							</p>
						</Col>

						<Col lg={1}></Col>
					</Row>
				</Container>
			</section>

			<section id="sustainability" className="section sustainability">
				<Container>
					<Row>
						<Col lg={1}></Col>

						<Col lg={5}>
							<h2 className="text-center text-md-start">
								We reduce, reuse, recycle, and rehome
							</h2>
						</Col>

						<Col lg={5}>
							<p>
								We are aware of how our business impacts the environment and our
								aim is to produce unique and creative content in an ethical and
								sustainable way.
							</p>
							<p>
								Our clients place a high value on sustainability, as do we. We
								put this principle into practice, offering paperless casting,
								plastic-free kits, low-carbon transportation, and much more.
							</p>
						</Col>

						<Col lg={1}></Col>
					</Row>
				</Container>
			</section>

			{/*
			<section id="history" className="section history">
				<Container>
					<div className="history__intro">
						<h2 className="text-center">Get to know us</h2>

						<p className="text-md-center history__intro">
							If you want to know how it all began and how we have grown to
							become one of the best known and award-winning production
							companies in CEE working in film and television, then read on.
						</p>
					</div>

					<ul className="timeline">
						<li className="timeline__item event">
							<div className="event__year">2007</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								It begins! Paprika Latino Studios was founded by Péter Marschall
								(1972-2020) in Hungary and Romania
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2008</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Bence Trunko and Kristof Dombovary joins to Paprika Latino
								Studios
								<br />
								<br />
								First great success: Fish on the Cake
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2009</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								The Great Expansion: Paprika Studios represented in 5 countries
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2012</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Big buy in. MTG (Viaplay Group) acquisition
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2013</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Akos Erdős joins to the company
								<br />
								<br />
								Gear up for even higher speed: international growth
								<br />
								<br />
								The Voice, Édes élet, Sztárban sztár, Rising Star, Ezek
								megőrültek, Éden Hotel
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2015</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Even more offices in CEE: Paprika Studios represented in 9-10
								countries
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2016</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Péter Marschall left Paprika, Ákos Erdős CEO
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2018</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">Hypewriter Pitch Forum</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2020</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
								<span className="event__separator-item line"></span>
							</div>
							<div className="event__description">
								Dancing with the Stars (TV2)
							</div>
						</li>

						<li className="timeline__item event">
							<div className="event__year">2023</div>
							<div className="event__separator">
								<span className="event__separator-item dot"></span>
							</div>
							<div className="event__description">Management buy out.</div>
						</li>
					</ul>
				</Container>
			</section>
            */}

			<SectionHypeWriter />
		</>
	);
};

export default About;
