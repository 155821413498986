import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../components/PageTitle";
import BodyClass from "../components/BodyClass";
import AutoplayVideo from "../components/AutoplayVideo";
import SectionHypeWriter from "../components/SectionHypeWriter";
import CountUpInViewPortProps from "../components/CountUpInViewPort";

import ImageCoverHero from "./../images/cover/paprika_homepage_header_photo_desktop.jpg";
import ImageCoverHeroMobile from "./../images/cover/paprika_homepage_header_photo_mobile.jpg";
import ImageCoverFiction from "./../images/cover/paprika_fiction_header_photo_desktop.jpg";
import ImageCoverReality from "./../images/cover/paprika_reality_header_photo_desktop.jpg";
import ImageCoverShorts from "./../images/cover/paprika_shorts_header_photo_desktop.jpg";
import ImageCoverProduction from "./../images/cover/paprika_services_header_photo_desktop.jpg";

const Home = () => {
	return (
		<>
			<PageTitle title="Home" />
			<BodyClass className="page-home" />

			<section id="hero" className="section hero">
				<AutoplayVideo
					src="/videos/paprika_homepage_header_video.mp4"
					poster={ImageCoverHero}
					posterMobile={ImageCoverHeroMobile}
					className="hero-video"
				/>
			</section>

			<section id="about" className="section about">
				<Container>
					<Row>
						<Col lg={1}></Col>

						<Col md={10} lg={5}>
							<h1>The soul of any show always lies in the story</h1>
						</Col>

						<Col lg={5}>
							<p>
								Our content and production teams in any of our 8 countries feel,
								understand, and live within the local cultural and social
								environment, enabling them to know whom, when, what, and most
								importantly, how to tell a captivating story so that it always
								hits home, inspires, stimulates and engages. As the leading
								production house in CEE, Paprika Studios is uniquely capable of
								developing and producing complex productions. Because you can
								never have enough good stories.
							</p>
						</Col>

						<Col md={1}></Col>
					</Row>
				</Container>
			</section>

			<section id="usp" className="section usp">
				<Container>
					<Row className="justify-content-md-center">
						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>&nbsp;</span>
								<h3 className="usp-item__big">
									<CountUpInViewPortProps end={30} suffix="+" duration={3} />
								</h3>
								<span>broadcasters</span>
							</div>
						</Col>

						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>&nbsp;</span>
								<h3 className="usp-item__big">
									<CountUpInViewPortProps end={17} duration={3} delay={0.5} />
								</h3>
								<span>languages</span>
							</div>
						</Col>

						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>over</span>
								<h3 className="usp-item__big">
									<CountUpInViewPortProps end={100} duration={3} delay={1} />
								</h3>
								<span>international titles produced</span>
							</div>
						</Col>

						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>&nbsp;</span>
								<h3 className="usp-item__mid">
									high scale <br /> productions
								</h3>
								<span>with local crews</span>
							</div>
						</Col>

						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>&nbsp;</span>
								<h3 className="usp-item__big">
									<CountUpInViewPortProps end={12} duration={3} />
								</h3>
								<span>genres</span>
							</div>
						</Col>

						<Col md={6} lg={3}>
							<div className="usp-item">
								<span>&nbsp;</span>
								<h3 className="usp-item__big">
									<CountUpInViewPortProps end={8} duration={3} />
								</h3>
								<span>countries</span>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="services" className="section services">
				<Container>
					<Row>
						<Col md={6}>
							<div className="services-image__wrapper services-image__wrapper--left">
								<AutoplayVideo
									src="/videos/paprika_fiction_header_video.mp4"
									poster={ImageCoverFiction}
									className="services-image"
								/>
							</div>
						</Col>
						<Col md={6}>
							<h2>Fiction</h2>
							<p>
								We create and develop original scripts in-house and produce
								daily and weekly drama series with local casts in multiple
								countries.
							</p>
							<div className="text-center text-lg-end">
								<Link to="/division/fiction" className="button">
									See division
								</Link>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<div className="services-image__wrapper services-image__wrapper--right">
								<AutoplayVideo
									src="/videos/paprika_reality_header_video.mp4"
									poster={ImageCoverReality}
									className="services-image"
								/>
							</div>
						</Col>
						<Col md={{ span: 6, order: "first" }}>
							<h2>
								Reality & <br className="d-none d-lg-block" />
								Entertainment
							</h2>
							<p>
								Paprika Entertainment: Producing top-tier international formats,
								from dazzling shiny floor shows to captivating culinary and
								engrossing exotic realities.
							</p>
							<div className="text-center text-lg-start">
								<Link
									to="/division/reality-and-entertainment"
									className="button"
								>
									See division
								</Link>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<div className="services-image__wrapper services-image__wrapper--left">
								<AutoplayVideo
									src="/videos/paprika_shorts_header_video.mp4"
									poster={ImageCoverShorts}
									className="services-image"
								/>
							</div>
						</Col>
						<Col md={6}>
							<h2>Short Form</h2>
							<p>
								Meet Paprika Shorts - where over 20 in-house short form content
								creatives are weaving magic in the heart of Budapest!
							</p>
							<div className="text-center text-lg-end">
								<Link to="/division/shorts" className="button">
									See division
								</Link>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md={6}>
							<div className="services-image__wrapper services-image__wrapper--right">
								<AutoplayVideo
									src="/videos/paprika_services_header_video.mp4"
									poster={ImageCoverProduction}
									className="services-image"
								/>
							</div>
						</Col>
						<Col md={{ span: 6, order: "first" }}>
							<h2>
								Production <br className="d-none d-lg-block" />
								Services
							</h2>
							<p>
								We are the only production company in the CEE market with
								manufacturing operations in eight countries, offering true
								one-stop production services to EU and overseas producers
								looking for market rates, film financing and locations.
							</p>
							<div className="text-center text-lg-start">
								<Link to="/division/production-services" className="button">
									See division
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<SectionHypeWriter />
		</>
	);
};

export default Home;
