import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../components/PageTitle";
import BodyClass from "../../components/BodyClass";
import VimeoVideo from "../../components/VimeoVideo";
import RelatedProjectsFiction from "../../components/RelatedProjectsFiction";

const ProjectLandOfTheBrave = () => {
	return (
		<>
			<PageTitle title="Melodrama / Têlenovela project" />
			<BodyClass className={["page-project", "melodrama"]} />

			<section id="hero" className="section project-hero">
				<div className="project-hero__background">
					<picture>
						<source
							srcSet=""
							media="(min-width: 992px)"
						/>
						<img
							src=""
							alt="Project cover"
							className="img-fluid"
						/>
					</picture>
				</div>

				<Container>
					<h1>Melodrama / Têlenovela</h1>
				</Container>
			</section>

			<section id="about" className="section project-about">
				<Container>
					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={8}>
							<h2 className="text-center text-md-start">About</h2>
						</Col>

						<Col lg={2}></Col>
					</Row>

					<Row>
						<Col lg={2}></Col>

						<Col md={6} lg={4}>
							<p>
								New Slovenian comedy series Têlenovela is now available on
								demand on Voyo Slovenija. In this hilariously absurd show Andrei
								Lenart performs in the recurring role of Primož, a movie star's
								butt double who becomes an actor.
							</p>
						</Col>

						<Col md={6} ld={4}>
							<ul className="project-details">
								<li className="project-details__item">
									<span>Country</span>
									<span>SLOVENIA</span>
								</li>

								<li className="project-details__item">
									<span>Client</span>
									<span>Pop</span>
								</li>

								<li className="project-details__item">
									<span>Seasons</span>
									<span>1</span>
								</li>
							</ul>
						</Col>

						<Col lg={2}></Col>
					</Row>
				</Container>
			</section>

			<section id="media" className="section project-media">
				<Container>
					<div className="project-video">
						<VimeoVideo videoId="" accessToken="" quality="1080p" />
					</div>
					<div className="project-gallery">
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
						<span>
							<img
								src=""
								alt="still"
								className="img-fluid"
								loading="lazy"
								draggable="false"
							/>
						</span>
					</div>
				</Container>
			</section>

			<RelatedProjectsFiction title={true} />
		</>
	);
};

export default ProjectLandOfTheBrave;
