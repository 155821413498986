import React, { useEffect } from "react";

type BodyClassProps = {
	className: string | string[];
};

const BodyClass: React.FC<BodyClassProps> = ({ className }) => {
	useEffect(() => {
		const classList = Array.isArray(className) ? className : [className];

		classList.forEach((c) => {
			document.body.classList.add(c);
		});

		return () => {
			classList.forEach((c) => {
				document.body.classList.remove(c);
			});
		};
	}, [className]);

	return null;
};

export default BodyClass;
