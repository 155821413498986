import React from "react";

import { Container, Row, Col } from "react-bootstrap";

const SectionHypeWriter = () => {
	return (
		<>
			<section id="hypewriter" className="section hypewriter">
				<Container>
					<Row>
						<Col lg={1}></Col>

						<Col lg={5}>
							<h2 className="text-center text-lg-start">
								Hypewriter <br className="d-none d-lg-block" /> Pitch Forum
							</h2>
						</Col>

						<Col lg={5}>
							<p>
								Paprika Studios and RTL Hungary launched Hypewriter in 2018 as
								the first international TV series pitch forum in Budapest,
								Hungary. Since its first edition, it has become the leading
								pitch forum in CEE, with hundreds and thousands of concepts
								submitted from all over the world every year. The winner,
								selected by an international jury of TV professionals, receives
								EUR 10,000 in prize money each year and a fully produced pilot
								episode produced by Paprika Studios which is broadcasted by RTL
								Hungary.
							</p>
							<div className="text-center text-lg-end">
								<a
									href="https://hypewriter.tv/"
									target="_blank"
									rel="noreferrer"
									className="button"
								>
									See more
								</a>
							</div>
						</Col>

						<Col lg={1}></Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default SectionHypeWriter;
