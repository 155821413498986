import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import { Link } from "react-router-dom";

interface RelatedProjectsShortsProps {
	title?: boolean;
}

const RelatedProjectsShorts: React.FC<RelatedProjectsShortsProps> = ({
	title = false,
}) => {
    return (
			<>
				<section id="further-projects" className="section further-projects">
					<Container>
						{title && <h2 className="text-center">Latest Shorts</h2>}

						<ul className="project__list">
							<li className="project">
								<ProjectCard
									title="Canesten: Intim történetek"
									to="/project/canesten-intim-tortenetek"
									thumbnail="/images/small/canesten.jpg"
									video="/videos/projects/canesten.mp4"
								/>
							</li>

							<li className="project">
								<ProjectCard
									title="Telekom - Helló biznisz!"
									to="/project/telekom-hello-biznisz"
									thumbnail="/images/small/telekom_hello_biznisz.jpg"
									video="/videos/projects/telekom_hello_biznisz.mp4"
								/>
							</li>

							<li className="project">
								<ProjectCard
									title="Footie? Now!"
									to="/project/footie-now"
									thumbnail="/images/small/footie_now.jpg"
									video="/videos/projects/footie_now.mp4"
								/>
							</li>
						</ul>

                        <div className="text-center mt-5">
                            <Link className="button" to="/work#shorts">
                                See more Shorts
                            </Link>
                        </div>
					</Container>
				</section>
			</>
		);
};

export default RelatedProjectsShorts;
